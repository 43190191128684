/*----------------------------------------------------------------------*/
export const SELECTED_PAYMENT_ORDER_TYPE = 'SELECTED_PAYMENT_ORDER_TYPE';
export const CLEAR_SELECTED_PAYMENT_ORDER_TYPE = 'CLEAR_SELECTED_PAYMENT_ORDER_TYPE';
/*----------------------------------------------------------------------*/


// Set Payment Order Date
export const SELECTED_PAYMENT_DATE = 'SELECTED_PAYMENT_DATE';
export const CLEAR_SELECTED_PAYMENT_DATE = 'CLEAR_SELECTED_PAYMENT_DATE';

// List order pay
export const LIST_ORDER_PAY_REQUEST = 'LIST_ORDER_PAY_REQUEST';
export const LIST_ORDER_PAY_REQUEST_SUCCESS = 'LIST_ORDER_PAY_REQUEST_SUCCESS';
export const LIST_ORDER_PAY_REQUEST_FAILURE = 'LIST_ORDER_PAY_REQUEST_FAILURE';
export const CLEAR_LIST_ORDER_PAY_DATA = 'CLEAR_LIST_ORDER_PAY_DATA';

export const GET_ORDER_PAY_BY_ID_REQUEST = 'GET_ORDER_PAY_BY_ID_REQUEST';
export const GET_ORDER_PAY_BY_ID_REQUEST_SUCCESS = 'GET_ORDER_PAY_BY_ID_REQUEST_SUCCESS';
export const GET_ORDER_PAY_BY_ID_REQUEST_FAILURE = 'GET_ORDER_PAY_BY_ID_REQUEST_FAILURE';

// Set order pay
export const SET_DETAIL_ORDER_PAY_DATA = 'SET_DETAIL_ORDER_PAY_DATA';
export const CLEAR_DETAIL_ORDER_PAY_DATA = 'CLEAR_DETAIL_ORDER_PAY_DATA';
export const DETAIL_ORDER_PAY_REQUEST = 'DETAIL_ORDER_PAY_REQUEST';
export const DETAIL_ORDER_PAY_REQUEST_SUCCESS = 'DETAIL_ORDER_PAY_REQUEST_SUCCESS';
export const DETAIL_ORDER_PAY_REQUEST_FAILURE = 'DETAIL_ORDER_PAY_REQUEST_FAILURE';

//New
export const CREATE_ORDER_PAY_REQUEST = 'CREATE_ORDER_PAY_REQUEST'
export const CREATE_ORDER_PAY_REQUEST_SUCCESS = 'CREATE_ORDER_PAY_REQUEST_SUCCESS'
export const CREATE_ORDER_PAY_REQUEST_FAILURE = 'CREATE_ORDER_PAY_REQUEST_FAILURE'
export const CLEAR_CREATE_ORDER_PAY = 'CLEAR_CREATE_ORDER_PAY'

//Edit
export const EDIT_ORDER_PAY_REQUEST = 'EDIT_ORDER_PAY_REQUEST'
export const EDIT_ORDER_PAY_REQUEST_SUCCESS = 'EDIT_ORDER_PAY_REQUEST_SUCCESS'
export const EDIT_ORDER_PAY_REQUEST_FAILURE = 'EDIT_ORDER_PAY_REQUEST_FAILURE'
export const CLEAR_EDIT_ORDER_PAY = 'CLEAR_EDIT_ORDER_PAY'
export const SET_ORDER_PAY_TO_EDIT = 'SET_ORDER_PAY_TO_EDIT';

//Delete
export const DELETE_ORDER_PAY_REQUEST = 'DELETE_ORDER_PAY_REQUEST'
export const DELETE_ORDER_PAY_REQUEST_SUCCESS = 'DELETE_ORDER_PAY_REQUEST_SUCCESS'
export const DELETE_ORDER_PAY_REQUEST_FAILURE = 'DELETE_ORDER_PAY_REQUEST_FAILURE'
export const CLEAR_DELETE_ORDER_PAY = 'CLEAR_DELETE_ORDER_PAY'

//Get affectations movements by ID
export const GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST = 'GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST'
export const GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST_SUCCESS = 'GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST_SUCCESS'
export const GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST_FAILURE = 'GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST_FAILURE'
export const CLEAR_GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY = 'CLEAR_GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY'

// List order pay types
export const LIST_PAY_ORDER_TYPES_REQUEST = 'LIST_PAY_ORDER_TYPES_REQUEST';
export const LIST_PAY_ORDER_TYPES_REQUEST_SUCCESS = 'LIST_PAY_ORDER_TYPES_REQUEST_SUCCESS';
export const LIST_PAY_ORDER_TYPES_REQUEST_FAILURE = 'LIST_PAY_ORDER_TYPES_REQUEST_FAILURE';

// List order pay last
export const LIST_PAY_ORDER_LAST_REQUEST = 'LIST_PAY_ORDER_LAST_REQUEST';
export const LIST_PAY_ORDER_LAST_REQUEST_SUCCESS = 'LIST_PAY_ORDER_LAST_REQUEST_SUCCESS';
export const LIST_PAY_ORDER_LAST_REQUEST_FAILURE = 'LIST_PAY_ORDER_LAST_REQUEST_FAILURE';
export const CLEAR_LIST_ORDER_PAY_LAST = 'CLEAR_LIST_ORDER_PAY_LAST';

// List provision order
export const LIST_PROVISION_ORDER_REQUEST = 'LIST_PROVISION_ORDER_REQUEST';
export const LIST_PROVISION_ORDER_REQUEST_SUCCESS = 'LIST_PROVISION_ORDER_REQUEST_SUCCESS';
export const LIST_PROVISION_ORDER_REQUEST_FAILURE = 'LIST_PROVISION_ORDER_REQUEST_FAILURE';
export const CLEAR_LIST_PROVISION_ORDER_DATA = 'CLEAR_LIST_PROVISION_ORDER_DATA';

// List affectations by order pay type
export const LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST = 'LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST';
export const LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST_SUCCESS = 'LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST_SUCCESS';
export const LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST_FAILURE = 'LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST_FAILURE';
export const CLEAR_LIST_AFFECTATION_BY_ORDER_TYPE_DATA = 'CLEAR_LIST_AFFECTATION_BY_ORDER_TYPE_DATA';

export const LIST_FIGURATIVE_BY_ORDER_ID_REQUEST = 'LIST_FIGURATIVE_BY_ORDER_ID_REQUEST';
export const LIST_FIGURATIVE_BY_ORDER_ID_REQUEST_SUCCESS = 'LIST_FIGURATIVE_BY_ORDER_ID_REQUEST_SUCCESS';
export const LIST_FIGURATIVE_BY_ORDER_ID_REQUEST_FAILURE = 'LIST_FIGURATIVE_BY_ORDER_ID_REQUEST_FAILURE';

export const LIST_WORK_CERTIFICATE_TYPES_REQUEST = 'LIST_WORK_CERTIFICATE_TYPES_REQUEST';
export const LIST_WORK_CERTIFICATE_TYPES_REQUEST_SUCCESS = 'LIST_WORK_CERTIFICATE_TYPES_REQUEST_SUCCESS';
export const LIST_WORK_CERTIFICATE_TYPES_REQUEST_FAILURE = 'LIST_WORK_CERTIFICATE_TYPES_REQUEST_FAILURE';
export const CLEAR_LIST_WORK_CERTIFICATE_TYPES_DATA = 'CLEAR_LIST_WORK_CERTIFICATE_TYPES_DATA';