import config from 'Config';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, buildURLQuery, httpPut } from './httpServices';

const PF_API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;

const FIGURATIVE_EXPEDITURES = `${PF_API_BASE_URI}/figurative-expeditures`;

// GET figurative expeditures
export const getFigurativeExpeditures = async (accessToken, params) => {
	let queryString = "";
	let URL = "";
	const options = buildDefaultOptions(accessToken);
	let response;

	if(params != undefined) {
		const setBuildURLQuery = {
			page: params?.page || 0,
			size: params?.size || 10,
			sort: params?.sort || 'id,asc',
			budgetTarget: params.filter.budgetTarget,
			budgetFilters: params.filter.budgetFilters,
			budgetTargetVisibleName: params?.filter?.budgetTargetVisibleName,
			name: params.filter.name,
			year: params.filter.year
		};		
		queryString = buildURLQuery(setBuildURLQuery);
		URL = `${FIGURATIVE_EXPEDITURES}?${queryString}`;
	} else {
		URL = `${FIGURATIVE_EXPEDITURES}`;
	}

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}
	
	return response;
};


// POST figurative expeditures
export const newFigurativeExpeditures = async (accessToken, params) => {

	const options = buildDefaultOptions(accessToken);
	let response;

	try {
		response = await httpPost(FIGURATIVE_EXPEDITURES, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};


// POST figurative expeditures
export const editFigurativeExpeditures = async (accessToken, params) => {
	let URL = `${FIGURATIVE_EXPEDITURES}/${params.id}`;
	const options = buildDefaultOptions(accessToken);
	let response;

	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error?.response || error;
	}
	return response;
};