import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import config from 'Config';
import { Container } from 'react-bootstrap';

import AboutModalComponent from 'src/components/general/AboutModalComponent';
import { tryGetVersionFundsRequestsApi, tryGetVersionCoreApi, tryGetVersionPresupuestoApi, tryGetVersionAuthApi } from 'src/redux/version/versionActionCreator';
import { getVersionFundsRequestsAPIData, getVersionFundsRequestsAPIIsFetching, getVersionCoreAPIData, getVersionCoreAPIIsFetching, getVersionPresupuestoAPIData, getVersionPresupuestoAPIIsFetching, getVersionAuthAPIData, getVersionAuthAPIIsFetching  } from 'src/redux/version/versionReducer';

const Footer = () => {
	const dispatch = useDispatch();

	//Year Current
	const yearCurrent = new Date();

	//Version Funds Requests Api
	// TODO: Se habilitará cuando oficialmente se ponga en Producción Pedidos de Fondos
	const versionFundsRequestsAPIData = useSelector( state => getVersionFundsRequestsAPIData(state) );
	const versionFundsRequestsAPIIsFetching = useSelector( state => getVersionFundsRequestsAPIIsFetching(state) );
	// Version Core Api
	const versionCoreAPIData = useSelector( state => getVersionCoreAPIData(state) );
	const versionCoreAPIIsFetching = useSelector( state => getVersionCoreAPIIsFetching(state) );
	// Version Presupuesto Api
	const versionPresupuestoAPIData = useSelector( state => getVersionPresupuestoAPIData(state) );
	const versionPresupuestoAPIIsFetching = useSelector( state => getVersionPresupuestoAPIIsFetching(state) );
	// Version Auth Api
	const versionAuthAPIData = useSelector( state => getVersionAuthAPIData(state) );
	const versionAuthAPIIsFetching = useSelector( state => getVersionAuthAPIIsFetching(state) );
	// FrontEnd
	const frontEnd = config?.appSettings?.version;

	const dataModal = {
		//version Funds Requests
		versionFundsRequestsAPIData : versionFundsRequestsAPIData,
		versionFundsRequestsAPIIsFetching : versionFundsRequestsAPIIsFetching, 
		//version Core
		versionCoreAPIData : versionCoreAPIData,
		versionCoreAPIIsFetching : versionCoreAPIIsFetching,
		//version Presupuesto
		versionPresupuestoAPIData : versionPresupuestoAPIData,
		versionPresupuestoAPIIsFetching : versionPresupuestoAPIIsFetching,
		//Version AuthAPI
		versionAuthAPIData : versionAuthAPIData,
		versionAuthAPIIsFetching : versionAuthAPIIsFetching,
		//FrontEnd
		frontEnd : frontEnd
	};
	
	// Modal Credit details
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

		
	const onClickSeeAboutModal = () => {
		handleShow(true);
		 // TODO: Se habilitará cuando oficialmente se ponga en Producción Pedidos de Fondos
		dispatch( tryGetVersionFundsRequestsApi() ); 
		dispatch( tryGetVersionCoreApi() );
		dispatch( tryGetVersionPresupuestoApi() );
		dispatch( tryGetVersionAuthApi() );
	};

	return( <Container>
		<footer id='footer' className='row page-footer font-weight-light footer-container fixed-bottom'>
			<div className='col-md-12'>
				<div className='text-right py-1 px-3 text-white'>
					© {yearCurrent.getFullYear()} Copyright: {config.appSettings.name} - <a className='font-italic text-white cursor-pointer font-weight-bold' onClick={() => onClickSeeAboutModal()} >Acerca de...</a>
				</div>
			</div>
		</footer>

		<AboutModalComponent
			data={dataModal}
			title={'Acerca de...'}
			titleCard={config.appSettings.name}
			show={show}
			handleClose={handleClose}
		/>

	</Container>
	);
};

export default Footer;