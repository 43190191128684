import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePermissions } from './hooks/usePermissions';
import { useDispatch } from 'react-redux';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';
import AppLoading from 'src/components/common/AppLoading';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { useProfileChecks } from './hooks/useProfileChecks';
import { downloadStreamFile } from 'src/utils/utils';
import {ROUTE_PATHS as ROUTES}  from 'src/routes';
import config from 'Config';
import axios from 'axios';

const API_BASE_URI = `${config.apis.coreApi.URL}`;

const MenuManuals = () => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const {
        profileName,
        securityPermissions,
        paramsToAlerts
    } = usePermissions();

    const {
        isProfileNameHTC,
        isProfileNameSA,
    } = useProfileChecks();

    // Manual de usuario - Operador de servicios
    const downloadUserManualServiceOperator = () => {
        setIsLoading(true);
        const url = API_BASE_URI + ROUTES.USER_MANUAL_SERVICE_OPERATOR;
        axios.get(
            url,
            { responseType: 'blob' }
        ).then((response) => {
            downloadStreamFile(response, 'Manual-SAFI-2-Operador-Servicio');
        }).finally(() => setIsLoading(false));
    };

    // Manual de usuario - Perfil HTC
    const downloadUserManualProfileHTC = () => {
        setIsLoading(true);
        const url = API_BASE_URI + ROUTES.USER_MANUAL_PROFILE_HTC;
        axios.get(
            url,
            { responseType: 'blob' }
        ).then((response) => {
            downloadStreamFile(response, 'Manual-de-Usuario-SAFI2-Perfil-HTC');
        }).finally(() => setIsLoading(false));
    };

    // if (!securityPermissions?.canView) return null;

    return (<>
        <NavDropdown
            className='active'
            title={<><FontAwesomeIcon icon={faBook} className='mr-1' />Manual</>}
            onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}
        >
            {(isProfileNameSA || !isProfileNameHTC) &&
                <NavDropdown.Item className="dropdown-item" onClick={downloadUserManualServiceOperator}>
                    Manual de Usuario - Operador de Servicio
                </NavDropdown.Item>
            }
            {(isProfileNameSA || isProfileNameHTC) &&
                <NavDropdown.Item className="dropdown-item" onClick={downloadUserManualProfileHTC}>
                    Manual de Usuario - Perfil HTC
                </NavDropdown.Item>
            }
            <NavDropdown.Item className="dropdown-item" href="https://kb.cgmisiones.gob.ar/">
                Base de conocimientos
            </NavDropdown.Item>
        </NavDropdown>
        <AppLoading isLoading={isLoading} />
    </>);
};

export default MenuManuals;