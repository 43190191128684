import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { DropdownSubmenu } from "react-bootstrap-submenu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/free-solid-svg-icons';
import NavDropdownItem from './NavDropdownItem';
import { usePermissions } from './hooks/usePermissions';
import moment from 'moment';
import { ROUTE_PATHS } from 'src/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getStatusApi } from 'src/redux/login/loginActionCreator';
import { tryGetActiveAlert } from 'src/redux/alert/alertActionCreator';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getExercisesListData } from 'src/redux/exercise/exerciseReducer';
import { useProfileChecks } from './hooks/useProfileChecks';
import { push } from 'connected-react-router';

const MenuReport = () => {
	const dispatch = useDispatch();
    const { 
        profileName,
        reportsPermissions,
        regionsPermissions,
        paymentOrdersPermissions,
        fundRequestsPermissions,
        deliveryOrdersPermissions,
        paramsToAlerts
    } = usePermissions();
    const { 
        isProfileNameSA,
        isProfileNameHTC,
        isProfileConsultBudget,
        isProfileBudgetDirection,
        isProfileBudgetAdministrator,
        isMinistryOfTreasury,
    } = useProfileChecks();

	const globalSelectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));
	const exerciseStatus = globalSelectedPeriod?.exerciseState;
	const exerciseInExecution = exerciseStatus == "EXECUTION";
	const exercisesListData = useSelector(state => getExercisesListData(state));

    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const dateToComplementary = exercisesListData?.records?.filter(item => item?.year === globalSelectedPeriod?.year)[0]?.periods?.filter(item => item?.name === 'COMPLEMENTARIO')[0]?.startDate;
    const endDateComplementary = exercisesListData?.records?.filter(item => item?.year === globalSelectedPeriod?.year)[0]?.periods?.filter(item => item?.name === 'COMPLEMENTARIO')[0]?.endDate;
    const activeComplementaryPeriod = (todayDate >=  moment(dateToComplementary).format('YYYY-MM-DD') && todayDate <=  moment(endDateComplementary).format('YYYY-MM-DD')) ? true : false;

    const isAvailableShowActionsRp = value => {
		let isValidYear = globalSelectedPeriod?.year == value
		let isStartDate = todayDate >=  moment(dateToComplementary).format('YYYY-MM-DD')
		let isEndDate = endDateComplementary >= todayDate
		let isAvailableYearActionsRP = isValidYear && isStartDate && isEndDate
		return isAvailableYearActionsRP;
	}

	const isAvailableYearReport = value => {
		let isAvailableYearReport =  globalSelectedPeriod?.year == value;
		return isAvailableYearReport;
	}

    if (!reportsPermissions?.canView) return null;

    return (
        <NavDropdown 
            className='active' 
            title={<><FontAwesomeIcon icon={faChartPie} className='mr-1'/>Reportes</>}
            onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]}
        >
            {/* { (reportsPermissions['analityc-organisms']?.canView ||
                    reportsPermissions['analityc-juridictions']?.canView ||
                    reportsPermissions['total-consolidated']?.canView ||
                    reportsPermissions['total-juridictions']?.canView ||
                    reportsPermissions['total-organism']?.canView ||
                    reportsPermissions['total-crossed']?.canView)
                    &&
                    <DropdownSubmenu title="Control presupuesto" onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >
                        {reportsPermissions['analityc-juridictions']?.canView &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_JURISDICTION_ANALYTICAL))}>
                                • Analítico por jurisdicción
                            </NavDropdown.Item>
                        }
                        {reportsPermissions['analityc-organisms']?.canView &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_ORGANISM_ANALYTICAL))}>
                                • Analítico por unidad de organización/organismo
                            </NavDropdown.Item>
                        }
                        {reportsPermissions['total-consolidated']?.canView &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_CONSOLIDATED))}>
                                • Total consolidado
                            </NavDropdown.Item>
                        }
                        {reportsPermissions['total-crossed']?.canView &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_TOTAL_CROSSED_PURPOSE_FUNCTION_OBJECT_EXPENDITURE))}>
                                • Total cruzado-Finalidad y función-Objeto del gasto
                            </NavDropdown.Item>
                        }
                        {reportsPermissions['total-juridictions']?.canView &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_JURISDICTION_TOTAL))}>
                                • Total por jurisdicción
                            </NavDropdown.Item>
                        }
                        {reportsPermissions['total-organism']?.canView &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_ORGANISM_TOTAL))}>
                                • Total por organización/organismo
                            </NavDropdown.Item>
                        }
                    </DropdownSubmenu>
            } */}

            {(reportsPermissions['execution-reports']?.canView || reportsPermissions['affectation-gda']?.canView || reportsPermissions['modify-by-legal']?.canView) &&
                <DropdownSubmenu title="Ejecución presupuesto">
                    {(!isProfileNameHTC && !isProfileConsultBudget && reportsPermissions['execution-reports']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_BUDGET_ANALYTICAL_ACCOUNTING_CREDIT_EXECUTION} title="• Analítico de contabilidad de presupuesto"/>
                    }
                    {(!isProfileNameHTC && !isProfileConsultBudget && reportsPermissions['execution-reports']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_BALANCE_STATUS} title="• Estado saldos"/>
                    }
                    {(!isProfileNameHTC && !isProfileConsultBudget && reportsPermissions['modify-by-legal']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_INSTRUMENT_LEGAL_MODIFY} title="• Modificaciones por instrumento legal"/>
                    }
                    {(!isProfileNameHTC && !isProfileConsultBudget && reportsPermissions['affectation-gda']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_AFFECTATIONS_BY_ADMINISTRATIVE_DOCUMENT} title="• Comprobantes por expediente"/>
                    }
                    {(reportsPermissions['execution-reports']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_DETAIL_PASSIVE_RESIDUES} title="• Detalle de Residuos Pasivos"/>
                    }
                    {(reportsPermissions['execution-reports']?.canView  && (exerciseInExecution || activeComplementaryPeriod)) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_DETERMINATION_PASSIVE_RESIDUES} title="• Determinación de Residuos Pasivos"/>
                    }
                    {(isProfileConsultBudget || reportsPermissions['execution-reports']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_BUDGET_STATUS_CREDIT_EXECUTION} title="• Estado ejecución presupuesto" />
                    }
                    {(isProfileConsultBudget || reportsPermissions['execution-reports']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_CREDIT_MODIFY_CREDIT_EXECUTION} title="• Estado modificación créditos" />
                    }
                    {(!isProfileConsultBudget && reportsPermissions['execution-reports']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_MOVEMENTS_STATUS_CREDIT_EXECUTION} title="• Estado movimiento créditos" />
                    }
                    {(!isProfileConsultBudget && reportsPermissions['execution-reports']?.canView) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION} title="• Listado pagos" />
                    }
                    {   /* 	TODO: Ver validacion del link */
                        /* 	reportsPermissions['execution-reports']?.canView */
                        false &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_BALANCE_STATUS_PAYMENT_LIST_CREDIT_EXECUTION} title="• Estado Saldos" />
                    }
                    {(reportsPermissions['execution-reports']?.canView && (isAvailableYearReport(2022) || isAvailableShowActionsRp(2023) || isAvailableShowActionsRp(2024))) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_PENDING_RESOLUTION_PASSIVE_RESIDUES} title="• Pendientes de Resolución de Residuos Pasivos" />
                    }
                    {   /* TODO: se descomentara este reporte cuando este creado desde el backend*/
                        /* { !profileNameHTC && reportsPermissions['execution-reports']?.canView &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_DELAYED_FILES_CREDIT_EXECUTION))}>
                                • Expedientes demorados
                            </NavDropdown.Item>
                    } */}
                    {(!isProfileNameHTC && regionsPermissions?.canCreate) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_TABLE_TOTALS_CREDIT_EXECUTION} title="• Totales" />
                    }
                    {   /* TODO: Se descomentara este reporte cuando este creado desde el backend*/
                        /* { !profileNameHTC && reportsPermissions['execution-reports']?.canView &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_WORKS_BUDGET_ANALYTICAL_RECORD_CREDIT_EXECUTION))}>
                                &nbsp;• Registro analítico presupuesto obras
                            </NavDropdown.Item>
                    } */}
                    {(reportsPermissions['execution-reports']?.canView && (isProfileNameSA || isProfileBudgetDirection || isProfileConsultBudget || isProfileBudgetAdministrator || isMinistryOfTreasury)) &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_ACCRUAL_BASIS} title="• Base Caja Devengado" />
                    }
                    {reportsPermissions['execution-reports']?.canView &&
                        <NavDropdownItem route={ROUTE_PATHS.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED} title="• Reportes generados" />
                    }
                </DropdownSubmenu>
            }

            {fundRequestsPermissions?.canView || paymentOrdersPermissions?.canView || deliveryOrdersPermissions?.canView &&
                !isProfileNameHTC ?
                    <DropdownSubmenu title="Ejecución financiera">
                        {/* !profileNameHTC &&  deliveryOrdersPermissions?.canView */
                            true &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_DELIVERY_ORDERS_FUND_REQUEST))}>
                                • Órdenes entrega
                            </NavDropdown.Item>
                        }
                        {/* !profileNameHTC && fundRequestsPermissions?.canView  */
                            true &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_PAYMENT_ORDER_ADMINISTRATIVE_SERVICE_FUND_REQUEST))}>
                                • Órdenes pago por servicio
                            </NavDropdown.Item>
                        }
                        { /* !profileNameHTC && fundRequestsPermissions?.canView  */
                            // true &&
                            // 	<NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_FUND_REQUEST))}>
                            // 		• Pedido fondos
                            //  </NavDropdown.Item>
                        }
                        { /* 	 !profileNameHTC && fundRequestsPermissions?.canView */
                            true &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_BANK_ACCOUNT_FUND_REQUEST))}>
                                • Pedido fondos por cuenta bancaria
                            </NavDropdown.Item>
                        }
                        { /* 	 !profileNameHTC && fundRequestsPermissions?.canView */
                            true &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_PROVIDER_FUND_REQUEST))}>
                                • Pedido fondos por proveedor
                            </NavDropdown.Item>
                        }
                        { /*  !profileNameHTC && fundRequestsPermissions?.canView */
                            true &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_SERVICE_DIRECTION_FUND_REQUEST))}>
                                • Pedido fondos por servicio
                            </NavDropdown.Item>
                        }
                        { /*  !profileNameHTC && fundRequestsPermissions?.canView */
                            false &&
                            <NavDropdown.Item onClick={() => dispatch(push(ROUTE_PATHS.REPORT_NEW_FUND_REQUEST))}>
                                • Nuevo Pedido Fondos
                            </NavDropdown.Item>
                        }
                    </DropdownSubmenu>
                : undefined
            }

            {/*
                /* !profileNameHTC && fundRequestsPermissions?.canView
                true &&
                    <Accordion>
                        <Accordion.Toggle as={Button} variant='link' eventKey='1' className='dropdown-item font-weight-bold rounded-0' onClick={() => dispatch(push(ROUTE_PATHS.REPORT_BY_ADMINISTRATIVE_VOUCHER))}>
                            Consulta de Expedientes
                        </Accordion.Toggle>
                    </Accordion>
            */}

        </NavDropdown>
    );
};

export default MenuReport;