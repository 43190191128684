import React,{ useState } from 'react'
import { useForm } from 'react-hook-form';
import { Form, Button, Table, Alert, Modal } from 'react-bootstrap';
import { faExclamationTriangle, faCheck, faBroom, faFilter } from '@fortawesome/free-solid-svg-icons';
import ActionIcon from 'src/components/general/ActionIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as rubrosJson  from 'App/assets/data/rubros.json';
import { multiFilter } from 'src/utils/utils';

const RubrosModal = (props) => {

const { register, reset, handleSubmit } = useForm();
const [objFilt, setObjFilt] = useState(rubrosJson?.default)
const hasObjFilt = objFilt?.length > 0;

const { setShowRubrosModal,
    showRubrosModal,
    setSelectedItems,
    selectedItems,
    setActiveFundRequestDetailDto,
    activeFundRequestDetailDto } = props;

const handleCloseModalRubro = () => setShowRubrosModal(false);

const clearRubroFilters= () => {
    reset();
    setObjFilt(rubrosJson.default)
};

const stopPropagate = (callback)=>{
    return (e) => {
        e.stopPropagation();
        callback(e);
    };
};

const onClickSelectedRubro = rubroSelected => {
    setActiveFundRequestDetailDto({...activeFundRequestDetailDto, categoryCode: rubroSelected.COD_DETALLADO});
    setSelectedItems(selectedItems.map(item => {
        if (item.paymentOrder.id == activeFundRequestDetailDto.paymentOrder.id){
            item.categoryCode = rubroSelected.COD_GRAL + ' - ' + rubroSelected.COD_DETALLADO;
        }
        return item;
    }));
    handleCloseModalRubro();
};

const handleSearchRubro = (data) => {
    setObjFilt(multiFilter(rubrosJson.default, data))
};

  return (
    <Modal.Dialog>
		<Modal
			scrollable={true}
			dialogClassName="modal-xl"
			show={showRubrosModal}
			onHide={handleCloseModalRubro}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
				<h5>Rubro</h5>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={stopPropagate(handleSubmit(handleSearchRubro))}>
					<Table striped bordered hover size='sm' responsive>
						<thead>
							<tr>
								<th className='text-center align-middle' width='8%'>Código General</th>
								<th className='text-center align-middle' width='8%'>Código Detallado</th>
								<th className='text-center align-middle' width='30%'>Descripción</th>
								<th className='text-center align-middle' width='20%'>Categoría General</th>
								<th className='text-center align-middle' width='20%'>Categoría Detallada</th>
								<th className='text-center align-middle th-minwidth'>Acciones</th>
							</tr>
							<tr className='secondary'>
								<th className='text-center align-middle'>
									<Form.Control className='text-center' size='sm' name='COD_GRAL' ref={register} type='number'/>
								</th>
								<th className='text-center align-middle'>
									<Form.Control className='text-center' size='sm' name='COD_DETALLADO' ref={register} type='number'/>
								</th>
								<th className='text-center align-middle'>
									<Form.Control className='text-center' size='sm' name='DESCRIPCION' ref={register} type='text'/>
								</th>
								<th className='text-center align-middle'>
									<Form.Control className='text-center' size='sm' name='CAT_GENERAL' ref={register} type='text'/>
								</th>
								<th className='text-center align-middle'>
									<Form.Control className='text-center' size='sm' name='CAT_DETALLE' ref={register} type='text'/>
								</th>
								<th className='text-center align-middle'>
									<div className='d-flex justify-content-around'>
											<ActionIcon
												size="lg"
												id="search-button-rubro"
												className="btn-primary search-button text-white-color"
												toolTipText="Filtrar"
                                                type='submit'
												icon={faFilter}
											/>
											<ActionIcon
												size="lg"
												id="clean-filter-rubro"
												className="btn-primary clean-filter text-white-color"
												toolTipText="Limpiar filtros"
												icon={faBroom}
												onClick={()=> clearRubroFilters()}
											/>
									</div>
								</th>
							</tr>
						</thead>
						<tbody className='text-black-color'>
							<>
								{hasObjFilt ?
									objFilt.map((item, index) => (
										<tr key={item.id}>
											<td className='text-center align-middle'>
												{item.COD_GRAL}
											</td>
											<td className='text-center align-middle'>
												{item.COD_DETALLADO}
											</td>
											<td className='text-center align-middle'>
												{item.DESCRIPCION}
											</td>
											<td className='text-center align-middle'>
												{item.CAT_GENERAL}
											</td>
											<td className='text-center align-middle'>
												{item.CAT_DETALLE}
											</td>
											<td className='text-center align-middle'>
												<ActionIcon size='lg' id={'select-rubro-' + index} toolTipText='Seleccionar' icon={faCheck} onClick={() => onClickSelectedRubro(item)} />
											</td>
										</tr>
									))
									:
									<tr>
										<td colSpan='13' className='text-center'>
												<Alert variant='info' className='mb-0'>
													<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
													No hay registros
												</Alert>
										</td>
									</tr>
								}
							</>
						</tbody>
					</Table>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleCloseModalRubro}>Cerrar</Button>
			</Modal.Footer>
		</Modal>
	</Modal.Dialog>
  )
}

export default RubrosModal