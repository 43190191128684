import config from 'Config';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPut, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.presupuestoApi.URL}`;
const EXPEDIENT_ENDPOINT_URI = '/expenses/administrative-documents';

export const listExpedient = async ( accessToken, params) => {

	const jsonFilter = params ? JSON.stringify(params) : undefined;

	const setbuildURLQuery = {
		page: params?.page || 0,
		pageSize: params?.pageSize || 10,
		filter: jsonFilter
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${API_BASE_URI}${EXPEDIENT_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

//Edit alert
export const editExpedient = async (accessToken, data) => {

	const URL = `${API_BASE_URI}${EXPEDIENT_ENDPOINT_URI}/${data?.id}`;
	const options = buildDefaultOptions(accessToken);

	const params = {
		title: data?.title || null,
		period_id: data?.period_id
	};

	let response;
	
	try {
		response = await httpPut(URL, params, options);
	}
	catch (error) {
		return error;
	}

	return response;
};