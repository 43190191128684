import React, { useState, useEffect } from 'react';
import config from 'Config';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Alert, Form, Table, Row, Col, Button, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faArrowLeft, faArrowRight, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import DropdownList from 'react-widgets/lib/DropdownList'
import 'react-widgets/dist/css/react-widgets.css';
import swal from 'sweetalert';

import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/stepIndicator/StepIndicator';
import { getNewAffectationSelectedCredits, getNewAffectationAdministrativeDocumentData, getNewAffectationData, getSetExtractAffectationData, gettNewAffectationIsFetching } from 'src/redux/affectation/affectationReducer';
import { createAffectation } from 'src/redux/affectation/affectationActionCreator';
import { setNewAffectationDataSelectedCredits, setExtractAffectation } from 'src/redux/affectation/affectationActions';
import { showError } from 'src/redux/globalData/globalDataActionCreator';
import { isNotEmptyArray, isNotEmptyObject } from 'src/services/validationService';
import { formatterPeso, dateToStringFormatedToShowUSA, parseIntOrUndefined, parseFloatOrUndefined, numberNegativeRed, isQualifiedValidDate } from 'src/utils/utils';
import { CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO, CREDIT_EXECUTION_NEW_AFFECTATION_STEP_FOUR } from 'src/utils/constants';
import { cancel, accept, newInvolvement, dateImpact, finalize, back, quotaNumber, detail, amountAffectation, typeImpact, detailImpact, creditBalance, numberCredit, creditDetail, impactsBudgetItems, answersSendNewAffectation, confirmationNewInvolvement, selectOption, paymentBalance, finalBalance, saldoOblPago, preventiveBalance, visibleName } from 'src/utils/label';
import NumberFormat from 'react-number-format';

import { tryGetLatestAffectationTransaction } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getLatestAffectationTransactionData } from 'src/redux/administrativeDocument/administrativeDocumentReducer';
import { clearLatestAffectationTransactionData } from 'src/redux/administrativeDocument/administrativeDocumentActions';

import AppLoading from 'src/components/common/AppLoading';

const CreditExecutionNewAffectationStepThreePage = () => {

	const dispatch = useDispatch();

	// Messages
	const dropdownPlaceholder = selectOption;

	// Date validation
	const todayDate = dateToStringFormatedToShowUSA(new Date());

	// Administrative document data
	const administrativeDocumentData = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
	const [ detailAffectation, setDetailAffectation ] = useState('');

	const extractAffectationData = useSelector(state => getSetExtractAffectationData(state));
	let isEmptyExtractAffectationData = isNotEmptyObject(extractAffectationData);

	// Credits
	const selectedSubcodes = useSelector(state => getNewAffectationSelectedCredits(state))?.map(item => {
		if (!item?.hasOwnProperty('affectationData')) {
			item.affectationData = {
				affectationType: undefined,
				subpartialBudget: undefined,
				date: undefined,
				amount: undefined,
				feeNumber: undefined
			};
		}
		return item;
	});
	const hasSelectedSubcodes = isNotEmptyArray(selectedSubcodes);
	const numberOfSelectedSubcodes = selectedSubcodes?.length;
	const newAffectationData = useSelector(state => getNewAffectationData(state));
	const newAffectationIsFetching = useSelector(state => gettNewAffectationIsFetching(state));

	useEffect(() => {
		dispatch(clearLatestAffectationTransactionData());
		
		const yearSelected = newAffectationData?.administrativeDocument?.year;
		const numberSelected = newAffectationData?.administrativeDocument?.number;
		const organismCodeSelected = newAffectationData?.administrativeDocument?.organizationCode;
		
		const params = {
			year: parseIntOrUndefined(yearSelected),
			number: parseIntOrUndefined(numberSelected),
			organismCode: parseIntOrUndefined(organismCodeSelected)
			};
			dispatch(tryGetLatestAffectationTransaction(params)).then( response => {
				let detailAffectationTransaction = response?.detail;
				if(detailAffectationTransaction){
					setDetailAffectation(detailAffectationTransaction);
				}
			});

		}, []);

	const latestAffectationTransactionData = useSelector(state => getLatestAffectationTransactionData(state));

	const formatNumber = (number) => {
		return number.replace(/\./g, '').replace(/\,/g, '.')
	};

	// Affectation types
	const onChangeAffectationType = (subcodeData, selectedAffectationTypeData) => {
		let newCredits = selectedSubcodes.map(item => {
			if (item.id == subcodeData.id) {
				item.affectationData.affectationType = selectedAffectationTypeData;
			}
			return item;
		});
		dispatch(setNewAffectationDataSelectedCredits(newCredits));
	};

	// Subpartial budget
	const onChangeSubpartialType = (subcodeData, supartialData) => {
		let newCredits = selectedSubcodes.map(item => {
			if (item.id == subcodeData.id) {
				item.affectationData.subpartialBudget = supartialData;
			}
			return item;
		});
		dispatch(setNewAffectationDataSelectedCredits(newCredits));
	};

	// Amount
	const onChangeAffectationAmount = (subcodeData, event) => {
		let newCredits = selectedSubcodes.map(item => {
			if (item.id == subcodeData.id) {
				item.affectationData.amount = parseFloatOrUndefined(event.value);
			}
			return item;
		});
		dispatch(setNewAffectationDataSelectedCredits(newCredits));
	};

	const onBlurAffectationAmount = (subcodeData, event) => {
		const amount = formatNumber(event);
		if(amount > subcodeData?.balance?.currentBalance && subcodeData?.affectationData?.affectationType?.value.indexOf('Preventiva') >= 0){
			preventiveNegativeBalance(subcodeData, true);
		}else{
			preventiveNegativeBalance(subcodeData, false);
		}
	};

	// Fee number
	const onChangeAffectationFeeNumber = (subcodeData, event) => {
		let newCredits = selectedSubcodes.map(item => {
			if (item.id == subcodeData.id) {
				item.affectationData.feeNumber = event;
			}
			return item;
		});
		dispatch(setNewAffectationDataSelectedCredits(newCredits));
	};

	const withValueLimit = (inputObj) => {
		const { value } = inputObj;
		const maxValue = 999;
		const minValue = -1;
		if (value <= maxValue && value >= minValue) 
		return inputObj;
	}

	//Detail
	const  onChangeAffectationDetail = (event) => {
		setDetailAffectation(event?.target?.value);
		dispatch(setExtractAffectation(event?.target?.value));
	}

	// Carousel
	const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
	const handleCarouselSelect = selectedIndex => {
		setCarouselActiveIndex(selectedIndex);
	};
	const onClickNextCarouselItem = () => {
		handleCarouselSelect(carouselActiveIndex + 1);
	}
	const onClickBackCarouselItem = () => { 
		handleCarouselSelect(carouselActiveIndex - 1); }

	// Back & Next Buttons
	const isCreditDataLoadedValid = () => {
		let retorno = false;

		if (hasSelectedSubcodes) {
			retorno = selectedSubcodes.reduce((accumulator, currentValue) => {
				let auxCondition = (
					currentValue?.affectationData?.amount &&
					(detailAffectation || extractAffectationData.length>0) &&
					isNotEmptyArray(currentValue?.affectationData?.affectationType?.id));
				return (accumulator && auxCondition);
			}, true);
			const isAdministrativeDocumentDataValid = (!!administrativeDocumentData?.organizationCode && !!administrativeDocumentData?.number && !!administrativeDocumentData?.year);
			retorno = (retorno && isAdministrativeDocumentDataValid);
		}
		return retorno;
	};
	const enableNextStepButton = (isCreditDataLoadedValid());
	const onClickBackButton = () => { dispatch(push(CREDIT_EXECUTION_NEW_AFFECTATION_STEP_TWO)) };
	const onClickNextStepButton = () => {
		swal({
			title: confirmationNewInvolvement,
			text: answersSendNewAffectation,
			icon: 'warning',
			buttons: [cancel, accept]
		})
			.then((userAccept) => {
				if (userAccept) {
					submitNewAffectation();
				}
			});
	};

	const submitNewAffectation = () => {
		let affectations = [];
		const detail = extractAffectationData.length>0 ? extractAffectationData : detailAffectation;

		if (hasSelectedSubcodes) {
			affectations = selectedSubcodes.map(item => {
				return {
					date: dateFrom,
					amount: item?.affectationData?.amount,
					fee_number: parseIntOrUndefined(item?.affectationData?.feeNumber),
					affectation_status_id: item?.affectationData?.affectationType?.id,
					sub_code_id: item?.id,
					sub_partial_budget_id: item?.affectationData?.subpartialBudget?.id,
					administrative_document: {
						organism_code: parseIntOrUndefined(administrativeDocumentData?.organizationCode),
						number: parseIntOrUndefined(administrativeDocumentData?.number),
						year: parseIntOrUndefined(administrativeDocumentData?.year),
						title: administrativeDocumentData?.title || ''
					},
					detail: detail
				};
			});
		}
	
		dispatch(createAffectation({ affectations }))
			.then(response => {
				if (response?.status == 200) {
					dispatch(push(CREDIT_EXECUTION_NEW_AFFECTATION_STEP_FOUR));
				}
				else if (response?.status == 422) {
					setErrorInCreditSlide(response?.data);
					showError(dispatch, response?.data["affectations.0.fee_number"][0] + 
					' ' + response?.data["affectations.0.fee_number"][1]);
				}
				else {
					showError(dispatch, response?.data?.message);
				}
			});
	};


	// Error handling
	const [errorInSlide, setErrorInSlide] = useState();
	const setErrorInCreditSlide = responseData => {
		const subcodeIdWithError = responseData?.reference;
		const errorMessage = responseData?.message;
		const errorObjToSet = {
			subcodeId: subcodeIdWithError,
			message: errorMessage,
		};
		setErrorInSlide(errorObjToSet);

		// Set visible subcode slide with error
		const subcodeIndex = searchSubcodeCarouselIndex(subcodeIdWithError);
		handleCarouselSelect(subcodeIndex);
	};

	let [negativeBalanceArray, setNegativeBalanceArray] = useState([]);
	const warningMessage = "El saldo de la partida quedará negativo";

	const preventiveNegativeBalance = (responseData, param) => {

		const subcodeIdWithError = responseData?.id;
		let budget = false;

		const errorObjectToSet = {
			subCodeId: subcodeIdWithError,
			param: param
		};

		setNegativeBalanceArray(negativeBalanceArray.map(item => {
			if(item?.subCodeId == errorObjectToSet?.subCodeId){
				item.param = param;
				budget = true;
			}
			return item;
		}));

		if(budget == false){
			setNegativeBalanceArray(prevArray => [...prevArray, errorObjectToSet]);
		}
	};

	const searchSubcodeCarouselIndex = subcodeId => {
		let retorno;
		selectedSubcodes.forEach((subcode, index) => {
			if (subcode?.id == subcodeId) {
				retorno = index;
			}
		});
		return retorno
	};

	// Date check
	const [dateFrom, setDateFrom] = useState(todayDate);

	const errorDateFrom = dateFrom != '' ? !isQualifiedValidDate(dateFrom) : false;

	const validateErrorDate = (params) => {
		const error = !isQualifiedValidDate(params);
		if (params == undefined) {
			return false;
		} else {
			return error;	
		}
	}

	const enableNextStepButtonAllow = ( !errorDateFrom && enableNextStepButton );

	return <Container fluid >
		<Card className='mb-5'>

			<PageTitle text={impactsBudgetItems} />

			<Container fluid >

				<StepIndicator steps={config.appSettings.steps.creditExecution} current={2} className='mt-2 mb-3' />

				<Card className='mb-3'>
					<Card.Header className='h6'>
						{newInvolvement}
					</Card.Header>
					<Card.Body>
						{
							hasSelectedSubcodes
								?
								<>
									<Card className='shadow mb-3' style={{ borderColor: 'gray', borderRadius: '2px' }}>
										<Card.Body className='bg-white'>
											<Carousel interval={null} indicators={false} controls={false} activeIndex={carouselActiveIndex} onSelect={handleCarouselSelect} fade>
												{
													selectedSubcodes?.map(subcodeData => {

														const hasSubpartialBudgetList = isNotEmptyArray(subcodeData?.subpartialBudgetList);

														return (	<Carousel.Item key={subcodeData?.id}>
																<Form.Group className='text-black-color' as={Row}>
																	<Col sm='6'>
																		<p className='h6 mb-3'>{creditDetail.toUpperCase()}</p>
																	</Col>
																	<Col sm='6'>
																		<p className='h6 mb-3' >DETALLES DEL EXPEDIENTE</p>
																	</Col>
																</Form.Group>

																<Form.Group className='text-black-color' as={Row}>
																	<Col sm='6' >
																		<Table striped borderless hover>
																			<tbody>
																				<tr>
																					<td className='text-left' md={4}>{numberCredit}:</td>
																					<td className='text-right' md={4} md-offset={8}>
																						{subcodeData?.completeNumber}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left' md={4}>{visibleName}:</td>
																					<td className='text-right' md={8}>
																						{subcodeData?.visibleName}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left font-weight-bold' md={4}>{creditBalance}:</td>
																					<td className={`${numberNegativeRed(subcodeData?.balance?.currentBalance)} + text-right font-weight-bold`} md={8}>
																						{formatterPeso.format(subcodeData?.balance?.currentBalance)}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left font-weight-bold' md={4}>{preventiveBalance}:</td>
																					<td className={`${numberNegativeRed(subcodeData?.balances?.totals?.preventive)} + text-right font-weight-bold`} md={8}>
																						{
																							!isNaN(subcodeData?.balances?.totals?.preventive) ?
																								formatterPeso.format(subcodeData?.balances?.totals?.preventive)
																								:
																								'$ 0,00'
																						}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left font-weight-bold' md={4}>{finalBalance}:</td>
																					<td className={`${numberNegativeRed(subcodeData?.balances?.totals?.definitive)} + text-right font-weight-bold`} md={8}>
																						{
																							!isNaN(subcodeData?.balances?.totals?.definitive)
																								?
																								formatterPeso.format(subcodeData?.balances?.totals?.definitive)
																								:
																								'$ 0,00'
																						}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left font-weight-bold' md={4}>{saldoOblPago}:</td>
																					<td className={`${numberNegativeRed(subcodeData?.balances?.totals?.payment_obligation)} + text-right font-weight-bold`} md={8}>
																						{
																							!isNaN(subcodeData?.balances?.totals?.payment_obligation)
																								?
																								formatterPeso.format(subcodeData?.balances?.totals?.payment_obligation)
																								:
																								'$ 0,00'
																						}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left font-weight-bold' md={4}>{paymentBalance}:</td>
																					<td className={`${numberNegativeRed(subcodeData?.balances?.totals?.payment)} + text-right font-weight-bold`} md={8}>
																						{
																							!isNaN(subcodeData?.balances?.totals?.payment)
																								?
																								formatterPeso.format(subcodeData?.balances?.totals?.payment)
																								:
																								'$ 0,00'
																						}
																					</td>
																				</tr>
																			</tbody>
																		</Table>
																	</Col>
																	<Col sm='6'>
																		<Table striped borderless hover>
																			<tbody>
																				<tr>
																					<td className='text-left' md={4}>Código de Organismo:</td>
																					<td className='text-right' md={8}>
																						{newAffectationData?.administrativeDocument?.organizationCode}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left' md={4}>Número:</td>
																					<td className='text-right' md={8}>
																						{newAffectationData?.administrativeDocument?.number}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left' md={4}>Año:</td>
																					<td className='text-right' md={8}>
																						{newAffectationData?.administrativeDocument?.year}
																					</td>
																				</tr>
																				<tr>
																					<td className='text-left' md={4}>Título:</td>
																					<td className='text-right' md={8}>
																						{newAffectationData?.administrativeDocument?.title}
																					</td>
																				</tr>

																			</tbody>
																		</Table>
																	</Col>
																</Form.Group>

															<hr className='my-4' />
															<span className='text-danger d-flex mandatory-label text-right font-weight-bold font-italic mp-2' >
																<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1  text-danger mandatory-asterisk' />
															Obligatorio
																</span>
															<p className='h6 mb-4 text-black-color'>{detailImpact.toUpperCase()}</p>

															<Row>
																<Col sm='6'>

																	<Form.Group className='text-black-color' as={Row}>
																		<Form.Label as={Col} sm='5' className='d-flex justify-content-end align-items-center pr-0'>
																			<FontAwesomeIcon icon={faAsterisk} size='xs' className='mb-2 mr-1 text-danger mandatory-asterisk' />
																			{typeImpact}:
																				</Form.Label>
																		<Col sm='6'>
																			<DropdownList
																				data={subcodeData?.availablesAffectationStatus}
																				value={subcodeData?.affectationData.affectationType?.id}
																				onChange={data => onChangeAffectationType(subcodeData, data)}
																				textField='value'
																				valueField='id'
																				placeholder={dropdownPlaceholder}
																				allowCreate='false'
																				selectIcon={<FontAwesomeIcon size='md' icon={faChevronDown} />}
																				searchIcon={<FontAwesomeIcon size='md' icon={faSearch} />}>
																			</DropdownList>
																		</Col>
																	</Form.Group>

																	<Form.Group className='text-black-color' as={Row}>
																		<Form.Label as={Col} sm='5' className='d-flex justify-content-end align-items-center pr-0'>
																			{subcodeData?.affectationData?.affectationType && subcodeData.affectationData.affectationType.sub_partial_required && hasSubpartialBudgetList && <FontAwesomeIcon icon={faAsterisk} size='xs' className='mb-2 mr-1 text-danger mandatory-asterisk' />}
																			{
																				subcodeData?.affectationData?.affectationType && subcodeData.affectationData.affectationType.sub_partial_required && hasSubpartialBudgetList
																					?
																					'Partida subparcial:'
																					:
																					null
																			}
																		</Form.Label>
																		<Col sm='6'>
																			{
																				subcodeData?.affectationData?.affectationType && subcodeData.affectationData.affectationType.sub_partial_required && hasSubpartialBudgetList
																					?
																					<DropdownList
																						data={subcodeData?.subpartialBudgetList}
																						value={subcodeData?.affectationData.subpartialBudget?.id}
																						onChange={data => onChangeSubpartialType(subcodeData, data)}
																						textField={item => (`${item?.number} - ${item?.name}`)}
																						valueField='id'
																						placeholder={dropdownPlaceholder}
																						allowCreate='false'
																						selectIcon={<FontAwesomeIcon size='md' icon={faChevronDown} />}
																						searchIcon={<FontAwesomeIcon size='md' icon={faSearch} />}>
																						required={subcodeData?.affectationData?.affectationType && subcodeData.affectationData.affectationType.sub_partial_required && hasSubpartialBudgetList}
																					</DropdownList>
																					:
																					null
																			}
																		</Col>
																	</Form.Group>

																	<Form.Group className='text-black-color' as={Row}>
																	</Form.Group>

																</Col>
																<Col sm='5'>

																	<Form.Group className='text-black-color' as={Row}>
																		<Form.Label as={Col} sm='5' className='d-flex justify-content-end align-items-center pr-0'>
																			<FontAwesomeIcon icon={faAsterisk} size='xs' className='mb-2 mr-1 text-danger mandatory-asterisk' />
																			{amountAffectation}:
																				</Form.Label>
																		<Col sm='7'>
																		<NumberFormat
																			onBlur={(event) => onBlurAffectationAmount(subcodeData, event.target.value)}
																			thousandSeparator={'.'} decimalSeparator={','}
																			decimalScale={2}
																			value={subcodeData?.affectationData?.amount}
																			className="form-control"
																			inputmode="numeric"
																			onValueChange={event => onChangeAffectationAmount(subcodeData, event)}
																			required
																		/>
																		</Col>
																	</Form.Group>

																	<Form.Group className='text-black-color' as={Row}>
																		<Form.Label as={Col} sm='5' className='d-flex justify-content-end align-items-center pr-0'>
																			{quotaNumber}:
																				</Form.Label>
																		<Col sm='7'>
																			<NumberFormat 
																			decimalScale={0}
																			inputMode='numeric'
																			isAllowed={(inputObj) => withValueLimit(inputObj)}
																			className="form-control"
																			allowNegative={false}
																			value={subcodeData?.affectationData?.feeNumber}
																			onChange={event => onChangeAffectationFeeNumber(subcodeData, event.target.value)}
																			/>
																		</Col>
																	</Form.Group>


																</Col>
															</Row>
															{
																(!!errorInSlide && errorInSlide?.subcodeId == subcodeData?.id)
																	?
																	<Row>
																		<Col>
																			<Alert variant='danger' className='mb-0'>
																				{errorInSlide?.message}
																			</Alert>
																		</Col>
																	</Row>
																	:
																	undefined
															}
															{
																(negativeBalanceArray.map(item => {
																	return (item?.subCodeId == subcodeData?.id && item?.param)&&
																	<Row className='mt-2'>
																		<Col>
																			<Alert variant='warning' className='mb-0'>
																				{warningMessage}
																			</Alert>
																		</Col>
																	</Row>
																}))
															}
														</Carousel.Item>
														);
													})
												}
											</Carousel>
										</Card.Body>
									</Card>

									<Form.Group className='d-flex justify-content-center align-items-center'>
										<Button size='xs' onClick={onClickBackCarouselItem} disabled={(carouselActiveIndex == 0)} className='nav-btn'>
											<FontAwesomeIcon icon={faArrowLeft} />
										</Button>

										<span className='px-3 text-black-color'>
											{`Partida ${carouselActiveIndex + 1}/${numberOfSelectedSubcodes}`}
										</span>

										<Button size='xs' onClick={onClickNextCarouselItem} disabled={(carouselActiveIndex == (numberOfSelectedSubcodes - 1))} className='nav-btn'>
											<FontAwesomeIcon icon={faArrowRight} />
										</Button>
									</Form.Group>
								</>
								:
								null
						}
					<Card className='shadow mb-3' style={{ borderColor: 'gray', borderRadius: '2px' }}>
										<Card.Body className='bg-white pb-0	'>
											<Row>
												<Col sm="6">
													<p className='h6 mt-2 pt-1 text-black-color' >DESCRIPCIÓN DE AFECTACIONES</p>
												</Col>
											</Row>
											<Col sm='5'>
												<Form.Group className='text-black-color' as={Row}>
													<Form.Label as={Col} sm='5' className='d-flex justify-content-end align-items-center pr-0'>
														<FontAwesomeIcon icon={faAsterisk} size='xs' className='mb-2 mr-1 text-danger mandatory-asterisk' />
														{dateImpact}:
													</Form.Label>
													<Col sm='7'>
														<Form.Control
															defaultValue = {todayDate}
															type='date'
															max={todayDate}
															onChange={event => setDateFrom(event.target.value)}
															required
														/>
														{
															validateErrorDate(dateFrom)
															?
																<div className="alert alert-danger form-field-error mb-0 py-1 mt-1" role="alert">
																{`${'Fecha inválida.'}`}
																</div>
															:
															null
														}
													</Col>
												</Form.Group>
											</Col>

											<Col sm='5'>
												<Form.Group className='text-black-color justify-content-end' as={Row}>
													<Form.Label as={Col} sm='5' className='d-flex justify-content-end align-items-center pr-0'>
													<FontAwesomeIcon icon={faAsterisk} size='xs' className='mb-2 mr-1 text-danger mandatory-asterisk' />
														{detail}:
													</Form.Label>
													<Col sm='7'>
														<Form.Control
															name="detail"
															id="detail"
															defaultValue={latestAffectationTransactionData?.detail ? latestAffectationTransactionData?.detail : (isEmptyExtractAffectationData ? '' : extractAffectationData) }
															maxlength='255'
															onChange={value => onChangeAffectationDetail(value)}
															required
														/>
													</Col>
												</Form.Group>
											</Col>

										</Card.Body>
									</Card>


						<Form.Group className='mt-3 d-flex justify-content-around'>
							<Button variant='danger' size='lg' onClick={onClickBackButton}>
								<FontAwesomeIcon className='mr-3' icon={faArrowLeft} />
								{back}
							</Button>
							<Button variant='success' size='lg'  onClick={onClickNextStepButton} disabled={!enableNextStepButtonAllow || newAffectationIsFetching}>
								{finalize}
							</Button>
						</Form.Group>
					</Card.Body>
					<AppLoading isLoading={newAffectationIsFetching} />
				</Card>
			</Container>
		</Card>
	</Container>;

};

export default CreditExecutionNewAffectationStepThreePage;