import React, { useState } from 'react';
import { Form, Table, Button, Modal } from 'react-bootstrap';
import { formatterPeso, mFormat } from 'src/utils/utils';
import ActionIcon from 'src/components/general/ActionIcon';
import { faSave, faTimes, faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';
import ModalCertificacionObraPublica from 'src/components/pages/OrderPay/modal/ModalCertificacionObraPublica';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormContext } from 'react-hook-form';

const WorkCertificateTable = (props) => {
	const [showPayOrderDetailModal, setShowPayOrderDetailModal] = useState(false);
	const [data, setData] = useState();
	const [index, setIndex] = useState();
	const [showEditModal, setShowEditModal] = useState(false);
	const { tipo, checkKeyDown, hookFormMethodsModal, hookFormMethodsModal:{handleSubmit} = {}, stopPropagate,
			onSubmitForm, ticketObj, handleOrganismOnChange, codeOrganism, organismList, breakDownWorkCertificateObj
	} = props?._hookFormMethodsModal || {};
    const onClickRemove = index => {
		props.setReceipts(props.receipts?.filter((item, i) => i !== index));
    };
    const _onSubmitForm = (arg)=>{
    	onSubmitForm(arg, index);
    	setShowEditModal(false);
    }

	const handleModal = (item) => {
		setData(item);
		setShowPayOrderDetailModal(true);
    };

    const editarRecibo = (item, index) =>{
    	ticketObj?.setTicketData(item.tickets);
		breakDownWorkCertificateObj?.setAmountData(item.breakDownWorkCertificate);
    	handleOrganismOnChange(item.codeOrganism);
		setData(item);
		setIndex(index);
    	setShowEditModal(true);
    }

	const [isReciptAmountValid, setIsReciptAmountValid] = useState(false);
	const isWorkCertificateValid = (tipo === 'CERTIFICADO_DE_OBRAS_PUBLICAS') ? isReciptAmountValid : true;

    return <>
			<Table striped bordered hover size='sm' responsive>
				<thead className='text-white-color'>
					<tr>
						<th className='text-center align-middle'>Número</th>
						<th className='text-center align-middle'>Tipo</th>
						<th className='text-center align-middle'>Fecha</th>
						<th className='text-center align-middle' width='30%'>Expediente</th>
						<th className='text-center align-middle'>Importe</th>
						<th className='text-center align-middle th-minwidth'>Acciones</th>
					</tr>
				</thead>
				<tbody className='text-black-color'>{
					props.receipts?.map((item, i) =>
						<tr key={i}>
							<td className='text-center align-middle tipeTableFormat tbTipo'>
								{item?.certificateNumber}
							</td>
							<td className='text-center align-middle actionTableFormat tbAction'>
								{item?.certificateType}
							</td>
							<td className='text-center align-middle'>
								{mFormat(item?.receiptDate)}
							</td>
							<td className='text-center align-middle'>
								{item?.administrativeDocument} - {item?.number} / {item?.year}
							</td>
							<td className='text-right align-middle'>
								{ formatterPeso.format(item.tickets.map((v) => v.amount)
									.flat()?.reduce((prev, next) => Number(prev) + Number(next)))
								}
							</td>
							<td className='text-center align-middle px-2 m-0 table-responsive btn-group'>
								<ActionIcon size='lg' toolTipText='Detalle' icon={faSearch}  onClick={() => handleModal(item)} />
								{handleSubmit && <ActionIcon size='lg' toolTipText='Editar' icon={faEdit}  onClick={() => editarRecibo(item, i)} />}
								<ActionIcon size='lg' hidden={props?.orderPayDetailData} toolTipText='Remover' icon={faTimes} className='text-danger' onClick={() => onClickRemove(i)} />
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<Modal show={showPayOrderDetailModal} onHide={() => setShowPayOrderDetailModal(false)} dialogClassName="modal-xl">
				<Modal.Header closeButton>
					<Modal.Title>Certificación de Obras Públicas</Modal.Title>
				</Modal.Header>
				<Modal.Body className='mt-4 text-black-color'>
					<ModalCertificacionObraPublica
						organismList={organismList}
						data={data}
						onlyRead
						setIsReciptAmountValid={setIsReciptAmountValid}
					/>
						<div className='d-flex justify-content-end mt-4 mb-3'>
							<Button type='button' variant='primary' size='lg' onClick={() => setShowPayOrderDetailModal(false)}>
								Cerrar
							</Button>
						</div>
				</Modal.Body>
			</Modal>
			{handleSubmit && 
				<Modal show={showEditModal} onHide={() => setShowEditModal(false)} dialogClassName="modal-xl">
					<Modal.Header closeButton>
						<Modal.Title>Certificación de Obras Públicas</Modal.Title>
					</Modal.Header>
					<Modal.Body className='mt-4 text-black-color'>
						<FormContext {...hookFormMethodsModal } >
							<Form className='mt-4 text-black-color' onKeyDown={(e) => checkKeyDown(e)} onSubmit={stopPropagate(handleSubmit(_onSubmitForm))} >
								<ModalCertificacionObraPublica
									organismList={organismList}
									breakDownWorkCertificateObj={breakDownWorkCertificateObj}
									data={data}
									ticketObj={ticketObj}
									codeOrganism={codeOrganism}
									handleOrganismOnChange={handleOrganismOnChange}
									tipo={tipo}
									setIsReciptAmountValid={setIsReciptAmountValid}
								/>
			                    <div className='d-flex justify-content-around mt-4 mb-3'>
			                        <Button type='button' variant='primary' size='lg' onClick={()=>setShowEditModal(false)}>
			                            Cancelar
			                        </Button>
			                        <Button
			                            type='submit'
			                            variant='success'
			                            size='lg'
										disabled={!isWorkCertificateValid}
									>
			                            Guardar
			                            <FontAwesomeIcon icon={faSave} className='ml-2 text-white-color ' />
			                        </Button>
								</div>
							</Form>	            
						</FormContext>
					</Modal.Body>
				</Modal>
			}
    </>
};

export default WorkCertificateTable;