import React, { useEffect, useState, useRef } from 'react';
import config from 'Config';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { Container, Card, Form, Spinner, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faAsterisk, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import AppLoading from 'src/components/common/AppLoading';

import PageTitle from 'src/components/general/PageTitle';
import FormFieldError from 'src/components/general/FormFieldError';
import StepIndicator from 'src/components/stepIndicator/StepIndicator';
import { showSuccess } from 'src/redux/globalData/globalDataActionCreator';
import { getSelectedLegalInstrument, getCreditProcessInProgress } from 'src/redux/credit/creditReducer';
import { getFormTitle } from 'src/redux/subcode/subcodeReducer';
import { clearSelectedLegalInstrument, setCreditProcessInProgress, setSelectedLegalInstrument } from 'src/redux/credit/creditActions';
import { tryCreateLegalInstrument, tryListLegalInstrument, tryDownloadFileOfLegalInstrument } from 'src/redux/legalInstrument/legalInstrumentActionCreator';
import { getLegalInstrumentCreateIsFetching, getLegalInstrumentListIsFetching } from 'src/redux/legalInstrument/legalInstrumentReducer';
import { clearListLegalInstrument } from 'src/redux/legalInstrument/legalInstrumentActions';
import { isNotEmptyArray, isNumeric } from 'src/services/validationService';
import { parseIntOrUndefined, isValidString, getYearsOptionsByParam } from 'src/utils/utils';
import { CREDIT_EXECUTION, CREDIT_STEP_TWO } from 'src/utils/constants';
import {tipe,next,cancel,file,description,numberlegalInstrument,numberAbbreviation,dateLegalInstrument,attachedFile,dowload,date,legalInstrumetnFound,required,budgetCredits,associatedLegalInstrument,numberLegalInstrumentRequiredMsg,fileMaxSizeMsg,dateRequiredMsg, associatedExpedient, expedient, numberValueMsg } from 'src/utils/label';
import { setNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationActions';
import { tryGetAdministrativeDocument, tryListGetAdministrativeDocument } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getNewAffectationAdministrativeDocumentData } from 'src/redux/affectation/affectationReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { setPreloadState } from 'src/redux/subcode/subcodeActions';
import { getPreloadState } from 'src/redux/subcode/subcodeReducer';
import { getAdministrativeListDocumentIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';

const CreditStepOnePage = () => {

	const dispatch = useDispatch();
	const { register, handleSubmit, errors, getValues, reset } = useForm();

	const LEGAL_INSTRUMENT_TYPES = config.appSettings.LEGAL_INSTRUMENTS_TYPES;

	const [isLoading, setIsLoading] = useState(false);

	// Validations
	const numberValidationObj = { required: numberLegalInstrumentRequiredMsg };
	const maxNumber = 2147483647;
	const descriptionValidationObj = { required: false };
	const fileValidationObj = {
		validate: file => {
			const sizeInMB = file[0]?.size / 1024 / 1024;
			return (sizeInMB >= 5 || sizeInMB == undefined) ? fileMaxSizeMsg : undefined;
		}
	};
	const dateValidationObj = { required: dateRequiredMsg };
	const todayDate = moment(new Date()).format('YYYY-MM-DD');
	const year = new Date().getFullYear();

	const [legalInstrumentCode, setLegalInstrumentCode] = useState(false);
	const [descriptionCode, setDescriptionCode] = useState(false);

	// Administrative document
	const getAdministrativeDocumentsIsFetching = useSelector(state => getAdministrativeListDocumentIsFetching(state));

	// Period
	const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

	const maxDate = selectedPeriod?.year < year ? moment(new Date(selectedPeriod?.year, 12, 0)).format('YYYY-MM-DD') : todayDate;

	//Expedient
	const minimumYear = 2000;
	const yearsOptions = getYearsOptionsByParam(minimumYear, selectedPeriod?.year);
	const yearInputRef = useRef();
	const numberInputRef = useRef();
	const titleInputRef = useRef();
	const organismCodeInputRef = useRef();

	const [dataCharacterSelected, setDataCharacterSelected] = useState(false);
	const [dataNumberSelected, setDataNumberSelected] = useState(false);
	const [dataYearSelected, setDataYearSelected] = useState(false);

	const subjectRequired = ((organismCodeInputRef?.current?.value && numberInputRef?.current?.value && isNumeric(yearInputRef?.current?.value)) && !isValidString(titleInputRef?.current?.value) && !isLoading) ? true : false;

	// Form data
	const formTitle = useSelector(state => getFormTitle(state));

	const selectedLegalInstrument = useSelector(state => getSelectedLegalInstrument(state));
	const createLegalInstrumentIsFetching = useSelector(state => getLegalInstrumentCreateIsFetching(state));
	const creditProcessInProgress = useSelector(state => getCreditProcessInProgress(state));

	// Legal instrument search
	const legalInstrumentSearchIsFetching = useSelector(state => getLegalInstrumentListIsFetching(state));

	// Preload
    const preloadState = useSelector(state => getPreloadState(state))?.data;
    const [ preload, setPreload ] = useState(preloadState);

	// Default data
	const defaultPreloadParams = {
		page: 0,
		pageSize: 1,
		period_id: selectedPeriod?.id,
	};

    const onPreloadChanged = e => {
        setPreload(e.target.checked);
        dispatch(setPreloadState(e.target.checked));
    };

	const getTypeObjById = typeId => LEGAL_INSTRUMENT_TYPES?.find(item => item.id == typeId);

	const onFormSubmit = data => {		
		data.legalInstrumentTypeName = getTypeObjById( data?.legalInstrumentTypeId )?.name;
		setExpedient();
		dispatch( tryCreateLegalInstrument(data) )
			.then( response => {
				if( response?.status == 200 ) {
					dispatch( setSelectedLegalInstrument(response?.data) );
					dispatch( push(CREDIT_STEP_TWO) );
				}
			});	
	};

	//expedient validation 
	const errorOrganizationCode = 'El código de organismo del expediente debe estar entre los rangos 1000 y 9999';
	const subjectMessageRequired = "IMPORTANTE: Ingrese el Asunto del Expediente el cual es requerido al cargar el Número de Expediente";

	const organismCodeValidation = (value) => {
		if ((parseIntOrUndefined(value) >= 1000 && parseIntOrUndefined(value) <= 9999) || value == '0000'){
            return true;
        } else {
			return false;
        }
	};

	const errorMesssageComponent = (message) => {
		return (
			<>
				<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
					{message}
				</div>
			</>
		);
	}

	const setExpedient = () => {

		const titleSelected = titleInputRef?.current?.value
		
		if (isNewAdministrativeDocument) {
			if(organismCodeValidation(dataCharacterSelected) && dataNumberSelected > 0 && dataYearSelected != 'Año' && isValidString(titleSelected)){
				dispatch(setNewAffectationAdministrativeDocumentData({
					year: yearInputRef.current?.value,
					number: numberInputRef.current?.value,
					organizationCode: organismCodeInputRef.current?.value,
					title: titleInputRef.current?.value
				}));
			}
		}
	};

	const [dateControlMsg, setDateControlMsg] = useState(false);


	const searchLegalInstrument = () => {

		let dateControl = '1970-01-01';
		let formaterDate = getValues('date');
		
		if (formaterDate && formaterDate < dateControl){
			setDateControlMsg('Fecha incorrecta.')
		} else if (formaterDate && formaterDate > dateControl){
			setDateControlMsg(false)
		}

	
		const yearSelected = new Date(getValues('date'))?.getFullYear();
		
		const searchParam = {
			number: getValues('number'),
			date: formaterDate,
			idLegalInstrumentType: getValues('legalInstrumentTypeId')
		};

		if (searchParam.number && !isNaN(yearSelected) && searchParam.idLegalInstrumentType && !dateControlMsg) {
			setIsLoading(true);
			dispatch(tryListLegalInstrument(searchParam)).then(data => {		
				const records = data?.records;
				if (Array.isArray(records) && isNotEmptyArray(records)) {
					reset();
					showSuccess(dispatch, legalInstrumetnFound);
					dispatch(setSelectedLegalInstrument(records[0]));
				}
			}).finally(() => setIsLoading(false));
		}
	};

	const allowGet = ( dateControlMsg );
	
	const allowPreload = () => {
		if(!validExpedientPreload && selectedAdministrativeDocument == undefined){
			return false;
		}else if(selectedAdministrativeDocument != undefined){
			return true;
		}
		return true;
	};

	const onClickRemoveSelectedLegalInstrument = () => {
		reset();
		dispatch(clearSelectedLegalInstrument());
		setEnableInputsLegalInstrument(true);
	};

	const onClickDownloadLegalInstrumentFile = () => {
		setIsLoading(true);
		dispatch(tryDownloadFileOfLegalInstrument(selectedLegalInstrument)).finally(() => setIsLoading(false));
	};

	const onChangeLegalIntrumentCode = value => {
        let code = parseIntOrUndefined(value);
        if (code < 1 || code > 99999) {
            setLegalInstrumentCode(true);
        } else {
            setLegalInstrumentCode(false);
        }
    };

	const onChangeDescriptionCode = value => {
        if (value.length >= 255) {
            setDescriptionCode(true);
        } else {
            setDescriptionCode(false);
        }
    };

	// onBlur expedient inputs
	const onBlurExpedientInputs = event => {
		const organismCodeSelected = organismCodeInputRef?.current?.value;
		const numberSelected = numberInputRef?.current?.value;
		const yearSelected = yearInputRef?.current?.value;
		
		setDataCharacterSelected(organismCodeSelected);
		setDataNumberSelected(numberSelected);
		setDataYearSelected(yearSelected);
		
		if (organismCodeSelected && numberSelected && isNumeric(yearSelected)) {
			setIsLoading(true);
			const params = {
				year: parseIntOrUndefined(yearSelected),
				number: parseIntOrUndefined(numberSelected),
				organismCode: organismCodeSelected,
				period_id: selectedPeriod?.id 
			};
			dispatch(tryGetAdministrativeDocument(params))
				.then(administrativeDocumentLoaded)
				.finally(() => setIsLoading(false));
		}	
	};	

	// Administrative document data
	const selectedAdministrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));
	const [isNewAdministrativeDocument, setIsNewAdministrativeDocument] = useState(false);

	const administrativeDocumentPreloadVerification = async function (completeLabel) {
		let hasPreloads = false;
		const params = {
			...defaultPreloadParams,
			filter: {
				completeLabel,
				subCodePreloadWithoutConversion: 1
			}
		};
		hasPreloads = await dispatch(tryListGetAdministrativeDocument(params)).then(response => {
			const preloadList = response?.data[0]?.subCodePreload;
			if(isNotEmptyArray(preloadList)){
				return true;
			};
			return false;
		})

		return hasPreloads;
	};

	// Clear form
	const clearForm = () => {
		yearInputRef.current.value = '';
		numberInputRef.current.value = '';
		organismCodeInputRef.current.value = '';
		titleInputRef.current.value = '';
	};

	// Administrative document loaded from endpoint
	const administrativeDocumentLoaded = async responseData => {
		if (responseData) {
			if (isNotEmptyArray(responseData)) {
				let hasPreloads = await administrativeDocumentPreloadVerification(responseData[0]?.completeLabel);
				clearForm();
				setIsNewAdministrativeDocument(false);
				setValidExpedient(true);
				let params = {
					number: responseData[0].number,
					organizationCode: responseData[0].organizationCode,
					title: responseData[0].title,
					year: responseData[0].year,
					completeLabel: responseData[0]?.completeLabel,
					hasPreloads: hasPreloads
				};
				dispatch(setNewAffectationAdministrativeDocumentData(params));

				if (responseData[0].relatedLegalInstrument?.number) {
					let paramsToSend = {
						id: responseData[0].relatedLegalInstrument?.id,
						description: responseData[0].relatedLegalInstrument?.description || "",
						number: responseData[0].relatedLegalInstrument?.number,
						legalInstrumentType: {
							id: responseData[0].relatedLegalInstrument?.legalInstrumentType?.id,
							name: responseData[0].relatedLegalInstrument?.legalInstrumentType?.name
						},
						date: responseData[0].relatedLegalInstrument?.date,
						fileName: responseData[0].relatedLegalInstrument?.fileName || null
					}

					showSuccess(dispatch, legalInstrumetnFound);
					dispatch(setSelectedLegalInstrument(paramsToSend));
				}else{
					setEnableInputsLegalInstrument(true);
				}
			}
			else {
				setIsNewAdministrativeDocument(true);
			}
		}
	};

	//close expedient
	const onClickRemoveSelectedExpedient = () => {
		dispatch(setNewAffectationAdministrativeDocumentData(undefined));
		setValidExpedient(true);
	};

	const onClickCancel = () => {
		dispatch(setNewAffectationAdministrativeDocumentData(undefined));
		setValidExpedient(true);
		reset();
		dispatch(clearSelectedLegalInstrument());
		setEnableInputsLegalInstrument(true);
		dispatch(push(CREDIT_EXECUTION));
	};

	//expedient validation
	const [ expedientCode, setExpedientCode] = useState(false);
	const [ correlativeCode, setCorrelativeCode] = useState(false);
	const [validExpedient, setValidExpedient] = useState(true);
	const [validExpedientPreload, setValidExpedientPreload] = useState(false);

	const validateExpedient = (code, number, year, title) => {
		if(organismCodeValidation(code) && number > 0 && number <= maxNumber && year != 'Año' && year && isValidString(title)){
			setEnableInputsLegalInstrument(true);
			setValidExpedientPreload(true);
			return true;
		} else if(!code && !number && (year == 'Año' || year == '') && !title){
			setEnableInputsLegalInstrument(true);
			setValidExpedientPreload(false);
			return true;
		} else {
			setEnableInputsLegalInstrument(false);
			setValidExpedientPreload(false);
			return false;
		}
	};

	const onChangeExpedientInput = ()=> {

        const organismCodeSelected = organismCodeInputRef?.current?.value;
		const numberSelected = numberInputRef?.current?.value;
		const yearSelected = yearInputRef?.current?.value;
		const titleSelected = titleInputRef?.current?.value;
		
		setValidExpedient(validateExpedient(organismCodeSelected, numberSelected, yearSelected, titleSelected));
		
        if (organismCodeValidation(organismCodeSelected) || !organismCodeSelected){
            setExpedientCode(false);
        } else {
			if( numberSelected != ''){
				setExpedientCode(true);
			} else {
				setExpedientCode(false);
			}
		}

		if((parseIntOrUndefined(numberSelected) <= 0 || parseIntOrUndefined(numberSelected) > maxNumber) && organismCodeSelected){
			setCorrelativeCode(true);
		} else {
			setCorrelativeCode(false);
		}
    }

	useEffect(() => {
		dispatch(clearListLegalInstrument());
		if (!creditProcessInProgress) {
			dispatch(clearSelectedLegalInstrument());
		}
		dispatch(setCreditProcessInProgress(true));
	}, []);

	const [enableInputsLegalInstrument, setEnableInputsLegalInstrument] = useState(false);

	const disabledLegalInstument = isLoading || selectedLegalInstrument || legalInstrumentSearchIsFetching; 
	const disabledAdministrativeDocument = isLoading || selectedAdministrativeDocument; 

	const modalAdminstrativeDocument = selectedAdministrativeDocument === undefined;
	
	const [fileInputValidation, setfileInputValidation] = useState(false)
	const fileTypeValidation = (value) => {
		// eslint-disable-next-line no-useless-escape
		var ext = value != '' ? value.match(/\.([^\.]+)$/)[1] : "";
		switch (ext) {
			case 'jpg':
			case 'png':
			case 'pdf':
			case '':
				setfileInputValidation(false);
				break;
				default:
				setfileInputValidation(true);
		}
	}
	return <Container  fluid className='mb-5'>
		<Card>
			<PageTitle text={budgetCredits} />

			<Container fluid >

				<div>
					<StepIndicator steps={config.appSettings.steps.creditControl} current={0} className='mt-2 mb-3' />
				</div>

				<Card className='mb-3'>
					<Card.Header>
						<h6 className='mb-0'>
							{formTitle}
						</h6>
					</Card.Header>
					<Card.Body >
				
							<Row>
								<Col xs={6}>
									<h6 className='mb-4 text-black-color'>
									{associatedExpedient}
									</h6>
								</Col>
								<Col xs={4}>
									<Form>
										<Form.Group className='text-black-color mt-0 mb-0 ml-2 mr-0 text-right font-weight-bold font-italic' controlId="formBasicCheckbox">
											<Form.Check.Input 
												style={{ "transform": 'scale(1.5)'}} 
												checked={preload} 
												onChange={onPreloadChanged}/>
											<Form.Check.Label className='ml-1'>PRECARGA</Form.Check.Label>
										</Form.Group>
									</Form>
								</Col>
								<Col xs={2} className='text-right font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{required}
								</span>
							</Col>
							</Row>						
							{
								selectedAdministrativeDocument
									?
									<>
										<div className='d-flex justify-content-center'>
											<Card className='text-black-color shadow bg-white rounded mt-3 mb-3 p-3' style={{ width: '20rem' }}>
												<FontAwesomeIcon icon={faTimes} className='text-black-color cursor-pointer close-button' onClick={onClickRemoveSelectedExpedient} />
												<Row className='my-2'>
													<Col className='text-right' xs='5'>
														Expediente:
													</Col>
													<Col>
														{`${selectedAdministrativeDocument?.organizationCode}-${selectedAdministrativeDocument?.number}/${selectedAdministrativeDocument?.year}`}
													</Col>
												</Row>
												<Row className='my-2'>
													<Col className='text-right' xs='5'>
														Asunto:
													</Col>
													<Col>
														{selectedAdministrativeDocument?.title}
													</Col>
												</Row>
											</Card>
										</div>
										<hr />
									</>
									:
									undefined
							}
							<Form.Group as={Row} className='text-black-color'>
								<Col sm='3' className='mb-0 d-flex align-items-center justify-content-end'>
									<Form.Label htmlFor='expediente-data' className='mb-0 d-flex align-items-center justify-content-end'>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' hidden={!preload}/>
										{expedient}:
									</Form.Label>
								</Col>
								<Col sm='7' className='mx-0 px-0  d-flex align-items-center justify-content-end'>
									<Form.Control
										type='number'
										id='expediente-data'
										name='codeOrganism'
										className='form-control w-100 d-flex align-items-center justify-content-end'
										disabled={disabledAdministrativeDocument}
										placeholder='Código'
										ref={organismCodeInputRef}
										onBlur={onBlurExpedientInputs}
										onChange={onChangeExpedientInput}
										title='Código de Organismo'
										min={'0000'}
										max={'9999'}
										step={'1'}
									/>
									<FormFieldError errors={errors?.codeOrganism} />
									<span className='px-2'>
										-
									</span>
									<Form.Control
										type='number'
										placeholder='Correlativo'
										name='correlative'
										className='form-control w-100 d-flex align-items-center justify-content-end'
										disabled={disabledAdministrativeDocument}
										ref={numberInputRef}
										onBlur={onBlurExpedientInputs}
										onChange={onChangeExpedientInput}
										min={'0'}
										max={maxNumber}
										step={'1'}
									/>
									<FormFieldError errors={errors?.correlative} />
									<span className='px-3'>
										/
									</span>
									<Form.Control
										as='select'
										disabled={disabledAdministrativeDocument}
										onBlur={onBlurExpedientInputs}
										onChange={onChangeExpedientInput}
										ref={yearInputRef}
										name='year'									
									>
										<option value={undefined} selected>Año</option>
										{
											yearsOptions?.map(item => (<option value={item}>
												{item}
											</option>))
										}
									</Form.Control>
									<FormFieldError errors={errors?.year} />
								</Col>
							</Form.Group>

							<Form.Group as={Row}>
								<Col sm='3' className='d-flex align-items-center justify-content-end'>
									<Form.Label htmlFor='asunto' className={ preload ? 'mb-0 text-black-color' : 'mb-0'}>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' hidden={!preload}/>
										Asunto:
									</Form.Label>
								</Col>
								<Col sm='7' className='px-0 d-flex align-items-center justify-content-end'>
									<Form.Control
										className='form-control w-100 d-flex align-items-center justify-content-end'
										placeholder='Asunto'
										id='asunto'
										ref={titleInputRef}
										disabled={disabledAdministrativeDocument}
										maxLength={'250'}
										onChange={onChangeExpedientInput}
									/>
								</Col>
							</Form.Group>
							{
								expedientCode
								?
								errorMesssageComponent(errorOrganizationCode)
								:
								correlativeCode
								?
								errorMesssageComponent(numberValueMsg)
								:
								subjectRequired
								?
								errorMesssageComponent(subjectMessageRequired)
								:
								null
                            }

							{
								(selectedAdministrativeDocument?.hasPreloads && preload) &&
									<div className="alert alert-warning form-field-error mb-0 py-1 mt-1" role="alert">
										Ya existe una precarga para el expediente indicado.
									</div>
							}

						<Row  hidden={preload}>
							<Col xs={8}>
								<h6 className='mb-4 mt-3 text-black-color'>
									{associatedLegalInstrument}
								</h6>
							</Col>
							{/* <Col xs={4} className='text-right mt-3 font-weight-bold font-italic'>
								<span className='text-danger d-flex mandatory-label'>
									<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
									{required}
								</span>
							</Col> */}
						</Row>

						{
							selectedLegalInstrument && !preload
								?
								<>
									<div className='d-flex justify-content-center'>
										<Card className='text-black-color shadow bg-white rounded mt-3 mb-3 p-3' style={{ width: '20rem' }}>
											<Row className="pb-3">
												<Col>
													<FontAwesomeIcon icon={faTimes} className='text-black-color cursor-pointer close-button' onClick={onClickRemoveSelectedLegalInstrument} />
												</Col>			
											</Row>
											<Row>
												<Col className='text-right'>
													{numberAbbreviation}:
												</Col>
												<Col>
													{selectedLegalInstrument.number}
												</Col>
											</Row>
											<Row>
												<Col className='text-right'>
													{tipe}:
												</Col>
												<Col>
													{selectedLegalInstrument.legalInstrumentType?.name}
												</Col>
											</Row>
											<Row>
												<Col className='text-right'>
													{date}:
												</Col>
												<Col>
													{moment(selectedLegalInstrument.date).format('DD/MM/YYYY')}
												</Col>
											</Row>
											<Row>
												<Col className='text-right' xs={6}>
													{description}:
												</Col>
												<Col xs={6}>
													{selectedLegalInstrument.description}
												</Col>
											</Row>
											{
												selectedLegalInstrument?.fileName
												?
													<Row>
														<Col className='text-right'>
															{attachedFile}:
														</Col>
														<Col>
															<a className='font-italic text-info cursor-pointer' onClick={onClickDownloadLegalInstrumentFile}>
																{dowload}
															</a>
														</Col>
													</Row>
												:
											null
											}
										</Card>
									</div>
									<hr />
								</>
								:
								null
						}

						<Form className='mt-4 text-black-color' onSubmit={handleSubmit(onFormSubmit)}>
						{ !preload && 
                			<Container>
								<Form.Group as={Row}>
									<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										{tipe}:
									</Form.Label>
									<Col sm={4}>
										<Form.Control
											as='select'
											name='legalInstrumentTypeId'
											disabled={disabledLegalInstument}
											ref={register}
											onChange={searchLegalInstrument}>
											{
												LEGAL_INSTRUMENT_TYPES?.map(item => (<option className='text-black-color' value={item.id} key={item?.id}>
													{item.name}
												</option>))
											}
										</Form.Control>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId='formHorizontalEmail'>
									<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										{numberlegalInstrument}:
									</Form.Label>
									<Col sm={4}>
										<Form.Control
											type='number'
											name='number'
											max={99999}
											min={1}
											placeholder={numberlegalInstrument}
											disabled={disabledLegalInstument}
											ref={register(numberValidationObj)}
											onBlur={searchLegalInstrument}
											onChange={event => onChangeLegalIntrumentCode(event.target.value)} />
										<FormFieldError errors={errors?.number} />
										{
											legalInstrumentCode
												?
												<>
													<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
														{'Valor no válido.'}
													</div>
												</>
												:
												null
										}
									</Col>
								</Form.Group>
								
								<Form.Group as={Row}>
									<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
										<FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
										{dateLegalInstrument}:
									</Form.Label>
									<Col sm={4}>
										<Form.Control
											type='date'
											name='date'
											disabled={disabledLegalInstument}
											ref={register(dateValidationObj)}
											max={maxDate}
											className='text-center'
											onBlur={searchLegalInstrument}
										/>
										<FormFieldError errors={errors?.date} />
										{
											dateControlMsg
											?
												<>
												<div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
												{`${dateControlMsg}`}
												</div>
												</>
											:
											null
										}
									</Col>
								</Form.Group>
								
								<Form.Group as={Row}>
									<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
										{description}:
									</Form.Label>
									<Col sm={4}>
										<Form.Control
											as='textarea'
											name='description'
											maxlength='255'
											disabled={disabledLegalInstument}
											ref={register(descriptionValidationObj)} 
											onChange={event => onChangeDescriptionCode(event.target.value)} />
										<FormFieldError errors={errors?.description} />
										{
											descriptionCode
												?
												<>
													<div className='alert alert-warning form-field-error mb-0 py-1 mt-2' role='alert'>
														{'Límite de caracteres alcanzado.'}
													</div>
												</>
												:
												null
										}
									</Col>
								</Form.Group>

								<Form.Group as={Row}>
									<Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm={4}>
										{file} (.pdf, .png, .jpg):
									</Form.Label>
									<Col sm={4}>
										<Form.Control
											type='file'
											name='file'
											accept='.pdf,.jpg,.png'
											onChange={event =>  fileTypeValidation(event.target.value)}
											disabled={disabledLegalInstument}
											ref={register(fileValidationObj)}
											className='text-black-color' />
										<FormFieldError errors={errors?.file} />
										{
											fileInputValidation && <FormFieldError errors={{message:'El archivo debe tener uno de estos formatos .pdf,.jpg,.png'}}/>
										}
										
									</Col>
								</Form.Group>
							</Container>
						}

							<div className='d-flex justify-content-around mt-4 mb-3'>
								<Button type='button' variant='danger' size='lg' disabled={createLegalInstrumentIsFetching} onClick={() => onClickCancel()}>
									{cancel}
								</Button>


								<span className={createLegalInstrumentIsFetching ? '' : 'hidden'}>
									<Spinner animation='border' variant='success' />
								</span>

								{
									preload 
									?
									<Button
										className='text-white-color'
										type='button'
										variant='success'
										size='lg'
										disabled={ !allowPreload() }
										onClick={() => { 
											setExpedient(); 
											dispatch(setPreloadState(preload))
											dispatch(push(CREDIT_STEP_TWO))}}>
										{next}
										<FontAwesomeIcon icon={faArrowRight} className='ml-2' />
									</Button>
									:
									selectedLegalInstrument
										?
										<Button
											className='text-white-color'
											type='button'
											variant='success'
											size='lg'
											disabled={legalInstrumentSearchIsFetching || !validExpedient }
											onClick={() => { 
												setExpedient(); 
												dispatch(push(CREDIT_STEP_TWO))}}>
											{next}
											<FontAwesomeIcon icon={faArrowRight} className='ml-2' />
										</Button>
										:
										<Button
											className='text-white-color'
											type='submit'
											variant='success'
											size='lg'
											disabled={ createLegalInstrumentIsFetching || legalInstrumentSearchIsFetching || allowGet || !validExpedient || fileInputValidation }>
											{next}
											<FontAwesomeIcon icon={faArrowRight} className='ml-2' />
										</Button>
								}
								{/* TODO: Se va a descomentar cuando este listo el paso 3 de modificacion de credito
									selectedLegalInstrument
										?
										<Button
											className='text-white-color'
											type='button'
											variant='success'
											size='lg'
											disabled={legalInstrumentSearchIsFetching || !validExpedient}
											onClick={() => { 
												setExpedient(); 
												dispatch(push(CREDIT_STEP_TWO_NEW))}}>
											{next + ' nuevo'}
											<FontAwesomeIcon icon={faArrowRight} className='ml-2' />
										</Button>
										:
										<Button
											className='text-white-color'
											type='submit'
											variant='success'
											size='lg'
											disabled={ createLegalInstrumentIsFetching || legalInstrumentSearchIsFetching || allowGet || !validExpedient}>
											{next + ' nuevo'}
											<FontAwesomeIcon icon={faArrowRight} className='ml-2' />
										</Button>
											*/}


							</div>
						</Form>
					</Card.Body>
				</Card>
			</Container>
		</Card>

		<AppLoading isLoading={isLoading || getAdministrativeDocumentsIsFetching} />

	</Container>

};

export default CreditStepOnePage;