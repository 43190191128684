import React, { useState, useEffect, useRef} from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from 'react-hook-form';
import { Row, Card, Image, Form, Button, Spinner, Alert , Container, InputGroup} from 'react-bootstrap';
import config from 'Config';
import ReCAPTCHA from 'react-google-recaptcha';

import logo from 'App/assets/images/logo_gobierno_misiones.png';
import logoContaduria from 'App/assets/images/logo_contaduria.png';

import LoggedOutCard from 'src/components/general/LoggedOutCard';
import FormFieldError from 'src/components/general/FormFieldError';
import { tryLogInAction } from 'src/redux/login/loginActionCreator';
import {login,forgetPassword,emailExample, password, email, passwordRequiredMsg, emailRequiredMsg, acces, testEnvironment} from 'src/utils/label';

import { FORGOT_PASSWORD_URI } from 'src/utils/constants';
import { getStatusApiLogin } from 'src/redux/login/loginActionCreator';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import backImg from '../../../assets/images/Background3.jpg';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

const LoginPage = () => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors } = useForm();

	const [isFetchingLogin, setIsFetchingLogin] = useState(false);
	const [visiblePassword, setVisiblePassword] = useState(false);
	const inputType = visiblePassword ? 'text' : 'password';

	const emailValidationObj = { required: emailRequiredMsg };
	const passwordValidationObj = { required: passwordRequiredMsg };

	const handleVisiblePassword = () => {
		document.getElementsByName('password')?.[0]?.focus();
		setVisiblePassword(!visiblePassword);
	};

	// reCaptcha V2
	const captchaRef = useRef();
	const onChangeCaptcha = tokenValue => {
		if (tokenValue) {
			handleSubmit(onClickSubmitForm)();
		}
	};

	const onClickSubmitForm = data => {
		if(statusPage === undefined) {
			dispatch(getStatusApiLogin()).then(response => setStatusPage(response));
			return undefined
		}
		setIsFetchingLogin(true);
		let recaptchaValue = false;

		if (!config.recaptchaAvailable) {
			recaptchaValue = null; 
		} else {
			captchaRef.current.execute();
			recaptchaValue = captchaRef.current.getValue();
		}
		
		const { email, password } = data;

		if (config.recaptchaAvailable && recaptchaValue) {
			dispatch(tryLogInAction(email, password)).finally(() => setIsFetchingLogin(false));
		} else if (!config.recaptchaAvailable ){
			dispatch(tryLogInAction(email, password)).finally(() => setIsFetchingLogin(false));
		}
	};

	const onClickGoToForgotPasswordPage = event => {
		event.preventDefault();
		dispatch(push(FORGOT_PASSWORD_URI));
	};
	const [statusPage, setStatusPage] = useState();

	useEffect( () => {
        dispatch(getStatusApiLogin()).then(response => setStatusPage(response));
    }, []);
	

	const cardBody = <>
        <Row className='d-flex justify-content-center align-items-center my-3'>
            <div className ='LoginPage__image--div col-md-6 my-2 text-center'>
            	<Image className='LoginPage__image' src={logo} fluid />
			</div>
			<div className ='LoginPage__image--div col-md-6 my-2 text-center'>
                <Image className='LoginPage__image' src={logoContaduria} fluid />
			</div>
		</Row>
		<Card.Title className='text-center mb-4 mt-3 mx-3 text-main-color'>
			<h4 className='LoginPage__title text-main-color'>
				{config.appSettings.name}
			</h4>
		</Card.Title>
		<hr />
		{
			statusPage != undefined && !statusPage ?
				<>
				<Container>
					<Row className='justify-content-center'>
						<Card className='shadow-lg my-1'>
							<Card.Body>
								<h3 className='LoginPage__subtitle text-black-color text-center mb-3'>Página en mantenimiento</h3>
								<Alert variant='info' className='mb-0'>
									<b className='text-black-color'>Aviso:</b> El sistema se encuentra en mantenimiento, por favor intente ingresar más tarde.
								</Alert>
							</Card.Body>
						</Card>
					</Row>
				</Container>
				<hr />
				</>
			:
			null
		}
		{
			config.environment != 'prod' &&
			<>
				<div className='alert alert-warning text-center mx-3' role='alert'>
					{testEnvironment}
					<FontAwesomeIcon 
						className='text-warning ml-2'
						icon={faExclamationCircle}s
					/>
				</div>
			</>
		}
		<h3 className='LoginPage__subtitle text-black-color text-center mb-3'>{login}</h3>
		<Form onSubmit={handleSubmit(onClickSubmitForm)}>
			<Form.Group className='mx-3' controlId='emailControl'>
				<Form.Label size='lg' className='text-primary'>
					{email}
				</Form.Label>
				<Form.Control
					size='lg' 
					type='email' 
					placeholder={emailExample} 
					name='email' 
					ref={register(emailValidationObj)} 
					className='placeholderOpacity'
					pattern="^[a-z0-9!#$%&'*+\/=?^_`\{\|\}~\-]+([a-z0-9!#$%&'*+\/=?^_`\{\|\}~\-\.])*@([a-z0-9]([a-z0-9]*[a-z0-9])?\.)+[a-z0-9]([a-z0-9]*[a-z0-9])?$"
				/>
				<FormFieldError errors={errors.email} />
			</Form.Group>
			<Form.Group className='mx-3' controlId='passwordControl'>
				<Form.Label size='lg' className='text-primary'>
					{password}
				</Form.Label>
				<InputGroup>
					<Form.Control size='lg' type={inputType} placeholder='•••••••' name='password' ref={register(passwordValidationObj)} className='placeholderOpacity' />
					<FontAwesomeIcon icon={visiblePassword ? faEye : faEyeSlash } size='lg' className='password-toogle-icon' onClick={() => handleVisiblePassword()}/>
				</InputGroup>
				<FormFieldError errors={errors.password} />
			</Form.Group>
			<Form.Group className='mx-3'>
				<Button size='lg' className='w-100 mt-3 btn-primary' type='submit' disabled={isFetchingLogin}>
					{
						isFetchingLogin ?
							<Spinner animation='border' />
							:
							acces
					}
				</Button>
			</Form.Group>
		</Form>
		<div className='text-center'>
			<a href='#' onClick={onClickGoToForgotPasswordPage}>
				¿{forgetPassword}?
			</a>
		</div>
	</>

	return (
		<div 
			className='LoginPage__container' 
			style={{
				backgroundImage: `url(${backImg})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center'
			}} 
		>
			<LoggedOutCard cardContent={cardBody} />
			{ config.recaptchaAvailable &&
				<ReCAPTCHA
					ref={captchaRef}
					sitekey={config.appSettings.googleSiteKey}
					size='invisible'
					onChange={onChangeCaptcha}
				/>
			}
		</div>
	)
};

export default LoginPage;