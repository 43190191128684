import React, { useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from "react-hook-form";
import { Row, Card, Image, Form, Button, Spinner } from 'react-bootstrap';
import config from 'Config';
import ReCAPTCHA from 'react-google-recaptcha';

import logo from 'App/assets/images/logo_gobierno_misiones.png';
import logoContaduria from 'App/assets/images/logo_contaduria.png';
import LoggedOutCard from 'src/components/general/LoggedOutCard';
import FormFieldError from 'src/components/general/FormFieldError';

import { trySendForgotPasswordEmail } from 'src/redux/login/loginActionCreator';
import { LOGIN_URI } from 'src/utils/constants';


const ForgotPasswordPage = () => {

	const dispatch = useDispatch();
	const { register, handleSubmit, reset, errors } = useForm();

	const [isFetchingEmail, setIsFetchingEmail] = useState(false);

	const emailRequiredMsg = 'Debe ingresar un correo electrónico.';
	const emailValidationObj = { required: emailRequiredMsg };

	const onClickGoToLoginPage = event => {
		event.preventDefault();
		dispatch( push(LOGIN_URI) );
	};

	// Captcha
	const captchaRef = useRef();
	const onChangeCaptcha = tokenValue => {
		if (tokenValue) {
			handleSubmit(onSubmit)();
		}
	};
	
	const onSubmit = data => {
		setIsFetchingEmail(true);
		let recaptchaValue;

		if (!config.recaptchaAvailable) {
			recaptchaValue = null;
		} else {
			captchaRef.current.execute();
			recaptchaValue = captchaRef.current.getValue();
		}

		if (config.recaptchaAvailable && recaptchaValue) {
			dispatch( trySendForgotPasswordEmail( data?.email, reset) ).then( response => {
				if(response?.status == 200){
					setIsFetchingEmail(false);
				}else{
					setIsFetchingEmail(false);
				}
			});
			dispatch( push(LOGIN_URI) );
		} else if (!config.recaptchaAvailable) {
			dispatch( trySendForgotPasswordEmail( data?.email, reset) ).then( response => {
				if(response?.status == 200){
					setIsFetchingEmail(false);
				}else{
					setIsFetchingEmail(false);
				}
			});
			dispatch( push(LOGIN_URI) );
		}
	};

	const forgottenPA = <>
		<Row className='h-100 justify-content-center align-items-center my-1'>
			<div className ='col-md-6 my-2'>
				<Image className='px-5' src={logo} fluid />
			</div>
			<div className ='col-md-6 px-5 my-2'>
				<Image className='px-5' src={logoContaduria} fluid />
			</div>
			
		</Row>

		<Card.Title className='text-center mb-4 mt-1 text-main-color texto-login'>
			<span className='h4 text-main-color'>
				{config.appSettings.name}
			</span>
		</Card.Title>

		<hr />

		<h6 className='text-center mb-3 text-black-color texto-login-d'>Envío de correo electrónico para recuperar contraseña</h6>

		<Form onSubmit={handleSubmit(onSubmit)}>
			<Form.Group className='mx-3' controlId='emailControl'>
				<Form.Label size='lg' className='text-primary'>
					Correo electrónico
				</Form.Label>
				<Form.Control 
					name='email' 
					size='lg' 
					type='email' 
					placeholder='example@example.com' 
					ref={register(emailValidationObj)} 
					className='placeholderOpacity'
					pattern="^[a-z0-9!#$%&'*+\/=?^_`\{\|\}~\-]+([a-z0-9!#$%&'*+\/=?^_`\{\|\}~\-\.])*@([a-z0-9]([a-z0-9]*[a-z0-9])?\.)+[a-z0-9]([a-z0-9]*[a-z0-9])?$"
				/>
				<FormFieldError errors={errors.email}/>
			</Form.Group>
			<Form.Group className='mx-3'>
				<Button size='lg' className='w-100 mt-3 btn-primary'  type='submit' disabled={isFetchingEmail}>
					{
						isFetchingEmail ?
							<Spinner className='spinner-border text-danger' animation='border'/>
						:
							'Enviar'
					}
				</Button>

				{ config.recaptchaAvailable &&
					<ReCAPTCHA
					ref={captchaRef}
					sitekey={config.appSettings.googleSiteKey}
					size='invisible'
					onChange={onChangeCaptcha}
					/> 
				}
			</Form.Group>
		</Form>

		<div className='text-center'>
			<a href='#' onClick={onClickGoToLoginPage}>
				Volver al Inicio de Sesión
			</a>
		</div>
	</>;

	return <LoggedOutCard cardContent={forgottenPA}/>;
};

export default ForgotPasswordPage;