import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormContext } from 'react-hook-form';
import { Container, Card, Form, Col, Spinner, Table } from 'react-bootstrap';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import PageTitle from 'src/components/general/PageTitle';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { showWarning } from 'src/redux/globalData/globalDataActionCreator';
import { getReportByURL } from 'src/services/reportsServices';
import { isNotEmptyArray } from 'src/services/validationService';
import { parseIntOrUndefined, formatterPeso, downloadStreamFile, numberNegativeRed } from 'src/utils/utils';
import ActionIcon from 'src/components/general/ActionIcon';
import { associatedVouchers, emptyMovementExp, reportExped, dateImpact, nroCredit, attachedFiles, amountNumber, seatNumber, dateOfOperation, typesOfAffectation, subpartialDeparture } from 'src/utils/label';
import moment from 'moment';

import { clearListAdministrativeDocumentHistoryData } from 'src/redux/administrativeDocument/administrativeDocumentActions';
import { tryGetAdministrativeDocumentHistory } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { getAdministrativeDocumentHistoryData, getAdministrativeDocumentHistoryIsFetching } from 'src/redux/administrativeDocument/administrativeDocumentReducer';

import AdministrativeDocumentForm from 'src/components/forms/administrativeDocument/administrativeDocumentForm';
import ReportExpedientTable from 'src/components/pages/Reports/ReportExpedientTable'

import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { faInfoCircle } from '../../../../node_modules/@fortawesome/free-solid-svg-icons/index';
import { FontAwesomeIcon } from '../../../../node_modules/@fortawesome/react-fontawesome/index';

const ReportAffectationsByAdministrativeDocumentPage = () => {

	const dispatch = useDispatch();
	const accessToken = useSelector(state => getAccessToken(state));
	const hookFormMethods = useForm();
	const { handleSubmit, getValues } = hookFormMethods;

	// administrative document history
	const administrativeDocumentHistory = useSelector(state => getAdministrativeDocumentHistoryData(state));
	const administrativeDocumentHistoryIsFetching = useSelector(state => getAdministrativeDocumentHistoryIsFetching(state));
	const hasAdministrativeDocument = isNotEmptyArray(administrativeDocumentHistory);

	//Period
	const idSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state)?.id);

	// Search administrative document
	const onClickSearchAdministrativeDocumentHistory = event => {
		const paramsToSendHistory = {
			organismCode: event?.organismCode,
			number: parseIntOrUndefined(event?.number),
			year: parseIntOrUndefined(event?.year),
			period_id: idSelectedPeriod
		};
			dispatch(tryGetAdministrativeDocumentHistory(paramsToSendHistory))
				.then(responseData => {
					if (responseData?.status == 200) {
						if (responseData?.data?.data?.length == 0) {
							showWarning(dispatch, emptyMovementExp);
						}
					}
				});
	};

	const [isFetchigReport, setIsFetchigReport] = useState(false);

	// Get transaction report
	const clickDownloadTransactionReportPDF = reportUrl => {
		setIsFetchigReport(true);
		getReportByURL(accessToken, reportUrl)
			.then(response => {
				if (response?.status == 200) {
					downloadStreamFile(response, 'reporte_transaccion');
					setIsFetchigReport(false);
				}
			});
	};

	// Clear old data
	useEffect(() => {
		dispatch(clearListAdministrativeDocumentHistoryData());
	}, []);

	// Transaction row
	const transactionRow = transactionData => <tr className='font-weight-bold'>
		<td colSpan='6' className='text-left align-middle'>
			Transacción {moment(transactionData?.transaction?.created_at).format('DD/MM/YYYY HH:mm')}
		</td>
		<td></td>
		<td>
			<div className='d-flex justify-content-around'>
				<ActionIcon size='lg' id={`descarga-${18}`} toolTipText={'Descargar'} icon={faFilePdf} onClick={() => clickDownloadTransactionReportPDF(transactionData?.transaction?.reportUrl)} />
			</div>
		</td>
	</tr>;

	// Affectations row
	const affectationRow = affectationData => <tr>
		<td className='text-center align-middle'>
			{affectationData?.accountingNumber}
		</td>
		<td className='text-center align-middle'>
			{moment(affectationData?.createdAt).format('DD/MM/YYYY')}
		</td>
		<td className='text-left align-middle'>
			{affectationData?.affectationStatusLabel}
		</td>
		<td className='text-center align-middle'>
			{moment(affectationData?.affectationDate).format('DD/MM/YYYY')}
		</td>
		<td className='text-center align-middle'>
			{affectationData?.subPartialBudget?.number}
		</td>
		<td className='text-left align-middle'>
			{affectationData?.budgetType + ': ' + affectationData?.budgetLabel}
		</td>
		<td className={`${numberNegativeRed(affectationData?.amount)} text-right align-middle`}>
			{formatterPeso.format(affectationData?.amount)}
		</td>
		<td></td>
	</tr>;

	return <>
			<Container fluid>
				<Card className='mb-3'>
					<Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Reporte comprobantes por expediente</h1>
                        <a 	className='text-white'
							target="_blank"
							href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/operador-servicios/#comprobante-por-expediente'
						>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header> 
					<Card.Body>
						<FormContext {...hookFormMethods}>
							<Form onSubmit={handleSubmit(onClickSearchAdministrativeDocumentHistory)}>
									<AdministrativeDocumentForm
										type = 'reportAdministrativeDocument'
										administrativeDocumentHistoryIsFetching = {administrativeDocumentHistoryIsFetching}	
									/>
								<Form.Group>
									<div className='text-center'>
										<Spinner
											animation='border'
											className={isFetchigReport ? 'spinner-border text-danger' : 'hidden'}
										/>
									</div>
								</Form.Group>
								{
									hasAdministrativeDocument
										?
										<>
										<ReportExpedientTable {...getValues()} period_id={idSelectedPeriod}/>
										<Col>
											<div className='d-flex justify-content-between align-items-center mb-3'>
												<span className='text-black-color h5 m-0'>
													{associatedVouchers}:
												</span>
											</div>

											<Table striped bordered hover size='sm'>
												<thead>
													<tr>
														<th className='text-center align-middle'>{seatNumber}</th>
														<th className='text-center align-middle'>{dateOfOperation}</th>
														<th className='text-center align-middle'>{typesOfAffectation}</th>
														<th className='text-center align-middle'>{dateImpact}</th>
														<th className='text-center align-middle'>{subpartialDeparture}</th>
														<th className='text-center align-middle'>{nroCredit}</th>
														<th className='text-center align-middle'>{amountNumber}</th>
														<th className='text-center align-middle'>{attachedFiles}</th>
													</tr>
												</thead>
												<tbody className='text-black-color'>
													{
														administrativeDocumentHistory?.map(transactionData => <>

															{
																(transactionData?.transaction)
																	?
																	transactionRow(transactionData)
																	:
																	undefined
															}

															{
																transactionData?.affectations?.map(affectationData => <>
																	{affectationRow(affectationData)}
																</>)
															}

														</>)
													}

												</tbody>
											</Table>
										</Col>
										</>
										:
										undefined
								}
						</Form>
						</FormContext>
					</Card.Body>
				</Card>
			</Container>
		</>
};

export default ReportAffectationsByAdministrativeDocumentPage;