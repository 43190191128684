import { push } from 'connected-react-router';

import * as SERVICES from 'src/services/userServices';
import * as ACTIONS from './userActions';
import { loginRequestSuccess } from 'src/redux/login/loginActions';

import {ROUTE_PATHS as ROUTES}  from 'src/routes';

import config from 'Config';
import { getAccessToken, getFirstLoginUser } from 'src/redux/login/loginReducer';
import { showSuccess, showError } from 'src/redux/globalData/globalDataActionCreator';

//User 
export const tryGetUserList = (page = 1, filter = '', profileId='', pageSize = 1000, cancelToken) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getUserListRequest());

		if (accessToken) {
			const response = await SERVICES.listUser(accessToken, page, pageSize, filter, profileId, cancelToken);

			if (response?.status == 200) {
				dispatch(ACTIONS.getUserListRequestSuccess(response?.data));
			}
			else if (response?.status == 499) {
				dispatch(ACTIONS.getUserListRequestFailure(response?.error));
				console.log('499 Client Closed Request');
			}
			else if (response?.error == undefined) {
				dispatch(ACTIONS.getUserListRequestFailure(response?.error));
				console.log('Usuario no encontrado');
			}
			else {
				dispatch(ACTIONS.getUserListRequestFailure(response?.error));
				showError(dispatch, `Error al cargar los usuarios. Comuníquese con el Área de Sistemas. Detalle: ${response.error}`);
			}

			return response;
		}
	};
};

//New User
export const tryPostUser = (name = '', alias = '', password = '', email = '', description = 'description', profileId) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getNewUserRequest());

		if (accessToken) {

			const response = await SERVICES.newUser(accessToken, name, alias, password, email, description, profileId);

			if (response?.status == 200) {
				dispatch(ACTIONS.getNewUserRequestSuccess(response?.data));
				showSuccess(dispatch, 'Usuario registrado correctamente, asignado a los servicios seleccionados.');
				const userToProfileParam = {
					userId: response?.data?.id,
					applicationId: config.apis.authApi.APPLICATION_ID,
					profileId
				};
				dispatch(tryAddUserToProfile(userToProfileParam));
			}
			else {
				dispatch(ACTIONS.getNewUserRequestFailure(response?.data?.error));
				showError(dispatch, `Error al crear un nuevo usuario. Detalle: ${response?.data?.error?.message}`);
			}
			return response;
		}

	};
};

//Edit User
export const tryPutUser = userData => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editUserRequest());

		if (accessToken) {

			const response = await SERVICES.editUser(accessToken, userData);
			if (response?.status == 200) {
				dispatch(ACTIONS.editUserRequestSuccess(response?.data));
				showSuccess(dispatch, 'Usuario editado correctamente.');
				dispatch(tryPutUserXApplicationXProfile(userData?.userXApplicationXProfile));
			}
			else {
				dispatch(ACTIONS.editUserRequestFailure(response?.error || response));
				showError(dispatch, `Error al editar el usuario. Detalle: ${response?.error || response}`);
			}
			return response;
		}

	};

};

export const tryPutPasswordFirstLoginUser = userData => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);
		const firstLoginUser = getFirstLoginUser(state);

		dispatch(ACTIONS.editUserRequest());

		if (accessToken) {
			const response = await SERVICES.editUser(accessToken, userData);
			if (response?.status == 200) {
				dispatch(ACTIONS.editUserRequestSuccess());
				dispatch(push(ROUTES.INDEX_PAGE));
				dispatch(loginRequestSuccess(firstLoginUser));
				showSuccess(dispatch, '¡Bienvenido!', `Ingresaste en ${config.appSettings.name}`);
			}
			else {
				dispatch(ACTIONS.editUserRequestFailure(response?.error || response));
				showError(dispatch, `Error al cambiar la contraseña. Detalle: ${response?.error || response}`);
			}
			return response;
		}

	};

};

//Delete User
export const tryDeleteUser = (id = 0) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.deleteUserRequest());

		if (accessToken) {

			const response = await SERVICES.deleteUser(accessToken, id);

			if (response?.status == 200) {
				dispatch(ACTIONS.deleteUserRequestSuccess(response?.data));
				showSuccess(dispatch, 'Usuario eliminado correctamente.');
				dispatch(push(ROUTES.USER_LIST));
			}
			else {
				dispatch(ACTIONS.deleteUserRequestFailure(response?.error || response));
				showError(dispatch, `Error al eliminar el usuario. Detalle: ${response?.error || response}`);
			}
		}

	};
};

//Profile
export const tryGetListUserProfile = (filter = '') => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getUserProfileRequest());

		if (accessToken) {

			const response = await SERVICES.listProfile(accessToken, filter);

			if (response?.status == 200) {
				dispatch(ACTIONS.getUserProfileRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getUserProfileRequestFailure(response?.error));
				showError(dispatch, `Error al cargar perfiles de usuarios. Detalle: ${response.error}`);
			}
		}

	};
};

// Add User X Application X Profile
export const tryAddUserToProfile = params => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.addUserToProfileRequest());

		if (accessToken) {

			const response = await SERVICES.postUserXApplicationXProfile(accessToken, params);

			if (response?.status == 200) {
				dispatch(ACTIONS.addUserToProfileRequestSucces());
				dispatch(push(ROUTES.USER_LIST));
			}
			else {
				dispatch(ACTIONS.addUserToProfileRequestFailure(response?.error || response));
				showError(dispatch, `Error al asignar perfil al usuario. Detalle: ${response?.error || response}`);
			}
		}

	};
};

//Edit User X Application X Profile
export const tryPutUserXApplicationXProfile = data => {

	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editUserXApplicationXProfileRequest());

		if (accessToken) {
			const response = await SERVICES.editUserXApplicationXProfile(accessToken, data);

			if (response?.status == 200) {
				dispatch(ACTIONS.editUserXApplicationXProfileRequestSuccess());
				dispatch(push(ROUTES.USER_LIST));
			}
			else {
				dispatch(ACTIONS.editUserXApplicationXProfileRequestFailure(response?.error || response));
				showError(dispatch, `Error al actualizar el perfil al usuario. Detalle: ${response?.error || response}`);
			}

			return response;
		}


	};
};

// List Users X Application X Profile
export const tryGetListUserXApplicationXProfile = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.listUsersXApplicationXProfile());

		if (accessToken) {

			const response = await SERVICES.listUsersXApplicationXProfile(accessToken);

			if (response?.status == 200) {
				dispatch(ACTIONS.listUsersXApplicationXProfileSucces(response?.data));
			}
			else {
				dispatch(ACTIONS.listUsersXApplicationXProfileFailure(response?.error || response));
				showError(dispatch, `Error al obtener el listado de relaciones de perfiles por usuario. Detalle: ${response?.error || response}`);
			}
		}

	};
};

// User Data
export const tryGetUserDetail = idUser => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getUserDetailRequest());

		if (accessToken) {

			const response = await SERVICES.getUserData(accessToken, idUser);

			if (response?.status == 200) {
				dispatch(ACTIONS.getUserDetailRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getUserDetailRequestFailure(response?.error || response));
				showError(dispatch, `Error al obtener el detalle de usuario. Detalle: ${response?.error || response}`);
			}
		}

	};
};

export const tryGetUserPaginatedList = (page, filter, profileId, pageSize, cancelToken) => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getUserPaginatedListRequest());

		if (accessToken) {
			const response = await SERVICES.listUser(accessToken, page, pageSize, filter, profileId, cancelToken);

			if (response?.status == 200) {
				dispatch(ACTIONS.getUserPaginatedListRequestSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getUserPaginatedListRequestFailure(response?.error));
				showError(dispatch, `Error al cargar usuario. Comuníquese con el Área de Sistemas. Detalle: ${response.error}`);
			}

			return response;
		}
	};
};

//Edit User
export const tryPutUserChangePassword = userData => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.editUserChangePasswordRequest());

		if (accessToken) {

			const response = await SERVICES.editUserChangePassword(accessToken, userData);
			if (response?.status == 200) {
				dispatch(ACTIONS.editUserChangePasswordRequestSuccess(response?.data));
				showSuccess(dispatch, 'Contraseña editada correctamente.');
			}
			else {
				dispatch(ACTIONS.editUserChangePasswordRequestFailure(response?.error || response));
				showError(dispatch, `Error al editar la contraseña. Detalle: ${response?.error || response}`);
			}
			return response;
		}

	};

};

// send validation email
export const trySendValidationEmail = idUser => {
	return async ( dispatch, getState ) => {

		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch( ACTIONS.sendValidationEmailRequest());

        const response = await SERVICES.sendValidationEmail(accessToken, idUser);
		
		if( response?.status == 200 ) {
			dispatch( ACTIONS.sendValidationEmailRequestSuccess());
			showSuccess( dispatch, 'El e-mail de activación fue enviado correctamente.' );
		}
		else {
			showError( dispatch, `Ocurrió un error al enviar el correo electrónico de activación. Detalle: ${response?.data?.error?.message||response}` );
		}
		
		return response;
	};
};

export const tryGetPersonaNameByCuit = cuit => {
	return async (dispatch, getState) => {
		const state = getState();
		const accessToken = getAccessToken(state);

		dispatch(ACTIONS.getPersonaNameByCuit());

		if (accessToken) {

			const response = await SERVICES.getPersonaNameByCuit(accessToken, cuit);

			if (response?.status == 200) {
				dispatch(ACTIONS.getPersonaNameByCuitSuccess(response?.data));
			}
			else {
				dispatch(ACTIONS.getPersonaNameByCuitFailure(response?.error || response));
				showError(dispatch, `Error al obtener persona por cuit. Detalle: ${response?.data?.message||response}`);
			}
			return response;
		}

	};
};