import config from 'Config';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.presupuestoApi.URL}`;
const API_BASE_URI_V2 = `${config.apis.presupuestoApi.URL_V2}`;
const PF_API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;

const ADMINISTRATIVE_DOCUMENT_URI = `${API_BASE_URI}/expenses/administrative-documents`;
const ADMINISTRATIVE_DOCUMENT_URI_V2 = `${API_BASE_URI_V2}/expenses/administrative-documents`;
const EXPEDIENT_BY_ID_BUGET_CREDIT_URI = `${API_BASE_URI}/expenses/administrative-documents`;
const ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_URI = `${API_BASE_URI}/expenses/affectations`;

const ADMINISTRATIVE_DOCUMENT_DELIVERY_ORDER = `${PF_API_BASE_URI}/administrative_documents`;
const ADMINISTRATIVE_DOCUMENT_BY_ORDEN_PAY_URI = `${PF_API_BASE_URI}/payment-orders/getAffectations`;

const ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_URI = `${API_BASE_URI}/expenses/administrative-documents`;

// Get administrative document service
export const getAdministrativeDocument = async (accessToken, params = {}) => {
	if (!params || !params?.year || !params?.number || !params?.organismCode) {
		return new Error('Invalid parameters.');
	}
	const URL = `${ADMINISTRATIVE_DOCUMENT_URI}/${params.organismCode}/${params.number}/${params.year}?period_id=${params.period_id}`;
	const options = buildDefaultOptions(accessToken);
	let response
	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}
	return response;
};

// Get administrative document service by period id
export const getAdministrativeDocumentByPeriodId = async (accessToken, params = {}) => {

	if (!params || !params?.year || !params?.number || !params?.organismCode) {
		return new Error('Invalid parameters.');
	};

	const setbuildURLQuery = {
		period_id: params?.periodId
	};
	const queryString = buildURLQuery(setbuildURLQuery);

	const URL = `${ADMINISTRATIVE_DOCUMENT_URI_V2}/${params.organismCode}/${params.number}/${params.year}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

export const getAllAdministrativeDocument = async (accessToken, {page=0,pageSize=100,filter={}}) => {

	const jsonFilter = filter ? JSON.stringify(filter) : undefined;

	const setbuildURLQuery = {
		page:page,
		pageSize:pageSize,
		filter: jsonFilter
	};

const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${ADMINISTRATIVE_DOCUMENT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

return response;

};

// Get administrative document by Order Pay service
export const getAdministrativeDocumentByOrderPay = async (accessToken, page=1, pageSize=10, filter={}) => {


	const setbuildURLQuery = {
		page:page,
		pageSize:pageSize,
		...filter
	};

	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${ADMINISTRATIVE_DOCUMENT_BY_ORDEN_PAY_URI}?${queryString}`;


	const options = buildDefaultOptions(accessToken);

	let response

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Get latest affectation transaction
export const getLatestAffectationTransaction = async (accessToken, params = {}) => {
	if (!params || !params?.year || !params?.number || !params?.organismCode) {
		return new Error('Invalid parameters.');
	}

	const URL = `${ADMINISTRATIVE_DOCUMENT_URI}/${params.organismCode}/${params.number}/${params.year}/latest-affectation-transaction`;
	const options = buildDefaultOptions(accessToken);

	let response

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};


// Get administrative document history
export const getAdministrativeDocumentHistory = async (accessToken, params = {}) => {

	if (!params || !params?.year || !params?.number || !params?.organismCode) {
		return new Error('Invalid parameters.');
	};

	const filter = {
		period_id: params?.period_id
	}

	const jsonFilter = filter ? JSON.stringify(filter) : undefined;

	const setbuildURLQuery = {
		filter: jsonFilter
	};
	const queryString = buildURLQuery(setbuildURLQuery);

	const URL = `${ADMINISTRATIVE_DOCUMENT_URI}/${params.organismCode}/${params.number}/${params.year}/affectation-history?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Get expedient by id budget credits actions
export const getExpedientByFilters = async (accessToken, params = {}) => {
	const {page, pageSize, filter} = params
	const jsonFilter = filter ? JSON.stringify(filter) : undefined;
	const setbuildURLQuery = {
		pageSize,
		page,
		filter: jsonFilter
		};
	const queryString = buildURLQuery(setbuildURLQuery);

	const URL = `${EXPEDIENT_BY_ID_BUGET_CREDIT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Get administrative document subcode related actions
export const getAdministrativeDocumentSubcodeRelated = async (accessToken, params = {}) => {
	const {page, pageSize, filter} = params
	const jsonFilter = filter ? JSON.stringify(filter) : undefined;
	const setbuildURLQuery = {
		pageSize,
		page,
		filter: jsonFilter
		};
	const queryString = buildURLQuery(setbuildURLQuery);

	const URL = `${ADMINISTRATIVE_DOCUMENT_SUBCODE_RELATED_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Get administrative document modified details actions
export const getAdministrativeDocumentModifPreloadDetails = async (accessToken, params = {}) => {
	const {page, pageSize, filter} = params
	const jsonFilter = filter ? JSON.stringify(filter) : undefined;
	const setbuildURLQuery = {
		pageSize,
		page,
		filter: jsonFilter
		};
	const queryString = buildURLQuery(setbuildURLQuery);
	const localURL = 'http://localhost:3000/sub-codes/administrative-documents/number';
	/* const URL = `${ADMINISTRATIVE_DOCUMENT_MODIF_PRELOAD_URI}/${params?.filter?.organismCode}/${params?.filter?.number}/${params?.filter?.year}/affectation-history`; */
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		/* response = await httpGet(URL, options); */
		response = await httpGet(localURL);
	}
	catch (error) {
		return error;
	}
	return response; 
};

// Get administrative document subcode related actions
export const getAllExpedientByServiceRP = async (accessToken, params = {}) => {
	const {page, pageSize, filter} = params
	const jsonFilter = filter ? JSON.stringify(filter) : undefined;
	const setbuildURLQuery = {
		pageSize,
		page,
		filter: jsonFilter
		};
	const queryString = buildURLQuery(setbuildURLQuery);

	const URL = `${EXPEDIENT_BY_ID_BUGET_CREDIT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};

// Get administrative document availables
export const getAdministrativeDocumentAvailables = async (accessToken, params) => {

	const queryString = buildURLQuery(params);
	const URL = `${ADMINISTRATIVE_DOCUMENT_DELIVERY_ORDER}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response

	try {
		response = await httpGet(URL, options);
	}
	catch (error) {
		return error;
	}

	return response;
};