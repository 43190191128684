import React, { useEffect, useRef, useState } from 'react';
import config from 'Config';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert, Form, Spinner, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faExclamationTriangle, faFilter, faBroom, faEdit, faTrash, faSearch, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import ActionIcon from 'src/components/general/ActionIcon';
import { useForm } from 'react-hook-form';
import { getGlobalDataSelectedPeriod, getFilterObject } from 'src/redux/globalData/globalDataReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { WAGES_TEMPLATE_EDIT, WAGES_TEMPLATE_NEW, WAGES_TEMPLATE_DETAIL } from 'src/utils/constants';
import { setFilterObject,  clearFilterObject} from 'src/redux/globalData/globalDataActions';
import AppLoading from 'src/components/common/AppLoading';
import RCPagination from 'src/components/common/RCPagination';
import { getRecruitmentMethodsData, getWageTemplateListData, getWageTemplateListIsFetching } from 'src/redux/wageTemplate/wageTemplateReducer';
import { tryDeleteWagesTemplate, tryGetWagesTemplateDetails, tryGetRecruitmentMethod } from 'src/redux/wageTemplate/wageTemplateActionCreator';
import { DropdownList } from 'react-widgets';
import { getListIdsServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceReducer';
import { clearWagesTemplateListData, setWageTemplateToEdit, setWageTemplateDetail } from 'src/redux/wageTemplate/wageTemplateActions';
import { AdministrativeServiceData, administrativeService } from 'src/utils/administrativeServiceUtils';
import { tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';

const WagesTemplateListPage = () => {
	const dispatch = useDispatch();
	const { handleSubmit, register, reset, getValues } = useForm();
	const figurativeExpedituresRefContainer = useRef(null);
	const colSpanCellsNumber = figurativeExpedituresRefContainer?.current?.cells.length;
	const [isLoading, setIsLoading] = useState(false);
	const environment = config.environment;
	const [contratacion, setContratacion] = useState();
	const administrativeServiceData = AdministrativeServiceData();
	const email = useSelector( state => getEmail(state) );
    const listUserData = useSelector(state => getUserListData(state));
    const userFound = listUserData?.records?.find(item => item.email == email);

	// Permissions
	const globalFilterObject = useSelector(state => getFilterObject(state)?.data);
    const listIdsServiceAdministrative = useSelector(state => getListIdsServiceAdministrativeByUser(state));
	const periodSelected = useSelector(state => getGlobalDataSelectedPeriod(state));
	const CancelToken = axios.CancelToken;
	let cancelSource = CancelToken.source();

	// Fetch Wage Template
	const wageTemplateListData = useSelector(state => getWageTemplateListData(state))
	const isFetching = useSelector(state => getWageTemplateListIsFetching(state));
	const records = wageTemplateListData?.records;
	const hasRecords = isNotEmptyArray(records);
	const recruitmentMethods = useSelector(state => getRecruitmentMethodsData(state));

	// Pagination
	const page = wageTemplateListData?.metadata?.page + 1;
	const totalItems = wageTemplateListData?.metadata?.filteredCount;
	const perPage= wageTemplateListData?.metadata?.pageSize;
	const defaultParams = {
		page: 0,
		size: 10,
		sort: 'id,desc',
		administrativeServiceId: listIdsServiceAdministrative
	};

	const loadAllData = () => {
		dispatch(tryListAllServiceAdministrativeByUser(userFound?.id)).then(response => {
			dispatch(tryGetWagesTemplateDetails({...defaultParams, administrativeServiceId: response?.data?.map((item)=>item.id).toString()}));
		});
		dispatch(tryGetRecruitmentMethod());
	}
	const onPageChange = (pNumber) => {
		const object = {
			...globalFilterObject,
			...defaultParams,
			page: pNumber - 1
		};
		fetchList(object);
	};
	const onClickNewWageTemplate = () => {
		dispatch(clearWagesTemplateListData());
		dispatch(push(WAGES_TEMPLATE_NEW));
	};
	const onClickDetail = (item) => {
        dispatch(setWageTemplateDetail(item));
        dispatch(push(WAGES_TEMPLATE_DETAIL));
    };
    const onClickEdit = wageTemplate => {
        dispatch(setWageTemplateToEdit(wageTemplate));
        dispatch(push(WAGES_TEMPLATE_EDIT));
    };
	const onClickDelete = idWageTemplate => {
		swal({
			title: 'Confirmación',
			text: '¿Realmente desea eliminar la plantilla de haberes?',
			icon: 'warning',
			buttons: ["Cancelar", "Aceptar"]
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(tryDeleteWagesTemplate(idWageTemplate?.id)).then(
					response => {
						if (response?.status == 204) {
							dispatch(clearWagesTemplateListData());
							loadAllData();
						}
					}
				);
			}
		});
	};
	const onSubmitForm = (data) => {
		if(!isFetching) {
			const filter = {
				name: data?.name,
				type: contratacion,
				...defaultParams
			}
			filter.administrativeServiceId = servicio || filter.administrativeServiceId;
			fetchList(filter);
		}
	};
	const fetchList = (params) => {
		dispatch(tryGetWagesTemplateDetails(params));
		dispatch(setFilterObject(params));
	};
	const cleanFilters = () => {
		dispatch(clearFilterObject());
		reset();
		setServicio(undefined);
		setContratacion(undefined);
		fetchList(defaultParams);
    };
	const handleSetContratacion = event => setContratacion(event.target.value);
	const [servicio, setServicio] = useState();
	const messagesDropDown = { emptyFilter: 'No hay resultados', emptyList: 'No hay resultados' };

	useEffect(() => {
		loadAllData();
		cleanFilters();
	}, []);

	return <Container fluid className='lisrtin'>
		<Card className='mb-5'>
			<Card.Header className='h6'>
				Plantilla de Haberes
			</Card.Header>
			<Card.Body>
				<div className='d-flex align-items-center mb-3'>
					<Button size='sm' className='mb-3' variant='success' onClick={onClickNewWageTemplate} >
						<FontAwesomeIcon icon={faPlus} className='mr-1' />
						Nueva plantilla de haberes
					</Button>
					<span className={(isFetching ? '' : 'hidden')}>
						<Spinner className='spinner-border text-danger' animation='border' size='sm' />
					</span>
				</div>
				<Form onSubmit={handleSubmit(onSubmitForm)} autocomplete='off' >
					<Table striped hover size='sm' responsive>
						<thead>
							<tr ref={figurativeExpedituresRefContainer}>
								<th className='text-center align-middle'>Nombre Plantilla</th>
								<th className='text-center align-middle'>Servicio</th>
								<th className='text-center align-middle'>Metodo de contratación</th>
								<th className='text-center align-middle th-minwidth'>Acciones</th>
							</tr>
							<tr className='secondary'>
								<th width="30%">
									<Form.Control className='text-center' type='string' name='name' ref={register}
										defaultValue={wageTemplateListData?.name ? globalFilterObject?.name : ""}
									/>
								</th>
								<th width="30%">
									<Col className='px-0 d-flex align-items-center justify-content-end' id='administrativeServiceId' >
										<DropdownList
											placeholder='Seleccione un servicio...'
											data={administrativeServiceData}
											allowCreate='onFilter'
											name='administrativeServiceId'
											textField='displayName'
											valueField='id'
											filter='contains'
											value={servicio}
											className='w-100 text-black-color'
											onChange={value => setServicio(value.id)}
											id='administrativeServiceId'
											messages={messagesDropDown}
											selectIcon={<FontAwesomeIcon size="md" icon={faChevronDown} className='text-black-color' />}
											searchIcon={<FontAwesomeIcon size="md" icon={faSearch} className='text-black-color' />}
										>
										</DropdownList>
									</Col>
								</th>
								<th width="40%">
									<Col>
										<Form.Control
											as='select'
											name='type'
											onChange={handleSetContratacion}
											value={contratacion}
											className='text-black-color'
										>
											<option value={''} selected>Seleccione una opcion...</option>
												{
													recruitmentMethods?.map((item, idx) => (
														<option value={item} key={idx}>
															{item.replace(/_/g, ' ')}
														</option>
													))
												}
										</Form.Control>
									</Col>
								</th>
								<th className='text-center align-middle'>
									<div className='d-flex justify-content-around'>
										<ActionIcon
											size="lg"
											id="search-button"
											className="btn-primary search-button text-white-color"
											toolTipText="Filtrar"
											icon={faFilter}
											type='submit'
											onSubmit={onSubmitForm}
										/>
										<ActionIcon
											size="lg"
											id="clean-filter"
											className="btn-primary clean-filter text-white-color"
											toolTipText="Limpiar filtros"
											icon={faBroom}
											type='reset'
											onClick={() => cleanFilters()}
										/>
									</div>
								</th>
							</tr>
						</thead>
						<tbody className='text-black-color'>
							{
								hasRecords ?
									records?.map(item => {
										return (
											<tr key={item?.id}>
												<td className='text-center align-middle truncate maxTruncate' >
													{item?.name}
												</td>
												<td className='align-middle truncate maxTruncate'>
													{administrativeService(item?.administrativeServiceId, administrativeServiceData)}
												</td>
												<td className='text-center align-middle truncate maxTruncate' >
													{item?.type.replace(/_/g, ' ')}
												</td>
												<td className='text-center align-middle p-0'>
													<ActionIcon
														size='lg'
														className={ 'mx-2' }
														toolTipText={'Detalle plantilla de haberes'}
														icon={faSearch}
														onClick={() => onClickDetail(item)}
													/>
													<ActionIcon
														size='lg'
														toolTipText={'Editar plantilla de haberes'}
														icon={faEdit}
														onClick={() => onClickEdit(item)}
													/>
													<ActionIcon
														size="lg"
														className={ 'mx-2' }
														toolTipText="Eliminar plantilla de haberes"
														icon={faTrash}
														onClick={() => onClickDelete(item) }
													/>
												</td>
											</tr>);
									})
									:
									<tr>
										<td colSpan={colSpanCellsNumber} className='text-center'>
											{
												!isFetching &&
												<Alert variant='info' className='mb-0'>
													<FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
													No hay partidas o no se posee permiso de acceso a las mismas.
												</Alert>
											}
										</td>
									</tr>
							}
						</tbody>
					</Table>
				</Form>
				<RCPagination
					activePage={page}
					itemsCountPerPage={perPage}
					totalItemsCount={totalItems}
					totalItems={totalItems}
					thePage={page}
					onChange={onPageChange}
					innerClass="justify-content-center"
				/>
			</Card.Body>
			</Card>
		<AppLoading isLoading={isLoading || isFetching} />
	</Container>;
};

export default WagesTemplateListPage;