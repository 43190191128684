import config from 'Config';
import * as ACTION_TYPES from './globalDataActionTypes';

import { getCurrentPeriod } from 'src/utils/utils'

export const globalDataReducerInitialState = {
	periods: {
		selectedPeriod: undefined,
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportDates: {
		data: undefined
	},
	filterObject : {
		data: undefined
	},
	selectedCredits : {
		data: undefined
	},
	bankAccountFilter: {
		data: undefined
	}
};

export const globalDataReducer = (state = globalDataReducerInitialState, action) => {
	switch (action.type) {
      
		case ACTION_TYPES.LIST_PERIODS_REQUEST: {
			return {
				...state,
				periods: {
					...state.periods,
					isFetching: true,
					hasError: false,
					error: undefined
				}
			};
		}
		case ACTION_TYPES.LIST_PERIODS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			/* TODO: Se quita el filtrado de exclusión del estado de Formulación en el listado de ejercicios
			data.data = data?.records?.filter( item => item?.exerciseState != 'FORMULATION' ); */
			data.data = data?.records;
			const currentPeriod = state.periods.selectedPeriod || getCurrentPeriod(data.data);
			return {
				...state,
				periods: {
					...state.periods,
					isFetching: false,
					hasError: false,
					selectedPeriod: currentPeriod,
					data
				}
			};
		}
		case ACTION_TYPES.LIST_PERIODS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				periods: {
					...state.periods,
					isFetching: false,
					hasError: true,
					error
				}
			};
		}

		case ACTION_TYPES.SET_SELECTED_PERIOD: {
			const { period } = action.payload;
			return {
				...state,
				periods: {
					...state.periods,
					selectedPeriod: period
				}
			};
		}

		case ACTION_TYPES.CLEAR_PERIODS_DATA: {
			return {
				...state,
				periods: {
					...globalDataReducerInitialState.periods
				}
			};
		}

		case ACTION_TYPES.SET_REPORT_DATES: {
			const { reportDates } = action.payload;
			return {
				...state,
				reportDates: {
					...state.reportDates,
					data: {
						dateFrom: reportDates.dateFrom || state.reportDates.data?.dateFrom,
						dateTo: reportDates.dateTo || state.reportDates.data?.dateTo
					}
				}
			};
		}

		case ACTION_TYPES.GET_FILTER_OBJECT: {
			return {
				...state.filterObject
			};
		}

		case ACTION_TYPES.SET_FILTER_OBJECT: {
			const { data } = action.payload;
			return {
				...state,
				filterObject: { 
					...state.filterObject,
					data: data
				}
			};
		}

		case ACTION_TYPES.CLEAR_FILTER_OBJECT: {
			return {
				...state,
				filterObject: {
					...globalDataReducerInitialState.filterObject
				}
			};
		}

		case ACTION_TYPES.GET_SELECTED_CREDITS: {
			return {
				...state.selectedCredits
			};
		}

		case ACTION_TYPES.SET_SELECTED_CREDITS: {
			const { data } = action.payload;
			return {
				...state,
				selectedCredits: { 
					...state.selectedCredits,
					data: data
				}
			};
		}

		case ACTION_TYPES.CLEAR_SELECTED_CREDITS: {
			return {
				...state,
				selectedCredits: {
					...globalDataReducerInitialState.selectedCredits
				}
			};
		}
		case ACTION_TYPES.GET_BANK_ACCOUNT_FILTERS: {
			return {
				...state.bankAccountFilter
			};
		}

		case ACTION_TYPES.SET_BANK_ACCOUNT_FILTERS: {
			const { data } = action.payload;
			return {
				...state,
				bankAccountFilter: { 
					...state.bankAccountFilter,
					data: data
				}
			};
		}

		case ACTION_TYPES.CLEAR_BANK_ACCOUNT_FILTERS: {
			return {
				...state,
				bankAccountFilter: {
					...globalDataReducerInitialState.bankAccountFilter
				}
			};
		}

		default: {
			return state;
		}
	}
};

export const getGlobalDataReducer = state => state.globalDataReducer;

export const getGlobalDataPeriods = state => getGlobalDataReducer(state)?.periods;

export const getGlobalDataPeriodsData = state => getGlobalDataPeriods(state)?.data;

export const getGlobalDataSelectedPeriod = state => getGlobalDataPeriods(state)?.selectedPeriod;

export const getReportDates = state => getGlobalDataReducer(state)?.reportDates;
export const getReportDatesData = state => getReportDates(state)?.data;

export const getFilterObject = state => getGlobalDataReducer(state)?.filterObject;

export const getBankAccountFilters = state => getGlobalDataReducer(state)?.bankAccountFilter;
