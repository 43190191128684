import * as ACTION_TYPES from './orderPayActionTypes';

//setSelectedOrderPay
export const setSelectedTypeOrderPay = data => ({
	type: ACTION_TYPES.SELECTED_PAYMENT_ORDER_TYPE,
	payload: {
		data
	}
});
export const clearSelectedTypeOrderPay = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_PAYMENT_ORDER_TYPE
});

//setSelectedDate
export const setSelectedDate = data => ({
	type: ACTION_TYPES.SELECTED_PAYMENT_DATE,
	payload: {
		data
	}
});
export const clearSelectedDate = () => ({
	type: ACTION_TYPES.CLEAR_SELECTED_PAYMENT_ORDER_TYPE
});

// List order pay actions
export const listOrderPayRequest = () => ({
	type: ACTION_TYPES.LIST_ORDER_PAY_REQUEST
});
export const listOrderPayRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_ORDER_PAY_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listOrderPayRequestFailure = error => ({
	type: ACTION_TYPES.LIST_ORDER_PAY_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const getOrderPayByIdRequest = () => ({
	type: ACTION_TYPES.GET_ORDER_PAY_BY_ID_REQUEST
});
export const getOrderPayByIdRequestSuccess = data => ({
	type: ACTION_TYPES.GET_ORDER_PAY_BY_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getOrderPayByIdRequestFailure = error => ({
	type: ACTION_TYPES.GET_ORDER_PAY_BY_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});


export const clearListOrderPayData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ORDER_PAY_DATA
});
// END List order pay actions

// Set Order Pay
export const detailOrderPayRequest = () => ({
	type: ACTION_TYPES.DETAIL_ORDER_PAY_REQUEST
});
export const detailOrderPayRequestSuccess = data => ({
	type: ACTION_TYPES.DETAIL_ORDER_PAY_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const detailOrderPayRequestFailure = error => ({
	type: ACTION_TYPES.DETAIL_ORDER_PAY_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const setOrderPayToDetail = data => ({
	type: ACTION_TYPES.SET_DETAIL_ORDER_PAY_DATA,
	payload: {
		data
	}
});
export const clearDetailOrderPayData = () => ({
	type: ACTION_TYPES.CLEAR_DETAIL_ORDER_PAY_DATA
});
// END Set Order Pay actions


// Add OrderPay  
export const getAddOrderPayRequest = () => ({
	type: ACTION_TYPES.CREATE_ORDER_PAY_REQUEST
});

export const getAddOrderPayRequestSuccess = (data) => ({
	type: ACTION_TYPES.CREATE_ORDER_PAY_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getAddOrderPayRequestFailure = error => ({
	type: ACTION_TYPES.CREATE_ORDER_PAY_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearCreateOrderPay=()=>({
	type:ACTION_TYPES.CLEAR_CREATE_ORDER_PAY
})
// END Add OrderPay 


// Edit OrderPay 
export const getEditOrderPayRequest = () => ({
	type: ACTION_TYPES.EDIT_ORDER_PAY_REQUEST
});

export const getEditOrderPayRequestSucces = (data) => ({
	type: ACTION_TYPES.EDIT_ORDER_PAY_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getEditOrderPayRequestFailure = error => ({
	type: ACTION_TYPES.EDIT_ORDER_PAY_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const setOrderPayToEdit = data => ({
	type: ACTION_TYPES.SET_ORDER_PAY_TO_EDIT,
	payload: {
		data
	}
});

export const clearEditOrderPay = () => ({
	type: ACTION_TYPES.CLEAR_EDIT_ORDER_PAY
})
// END Edit OrderPay

// Delete OrderPay 
export const getDeleteOrderPayRequest = () => ({
	type: ACTION_TYPES.DELETE_ORDER_PAY_REQUEST
});

export const getDeleteOrderPayRequestSuccess = (data) => ({
	type: ACTION_TYPES.DELETE_ORDER_PAY_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const getDeleteOrderPayRequestFailure = error => ({
	type: ACTION_TYPES.DELETE_ORDER_PAY_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearDeleteOrderPay = () => ({
	type: ACTION_TYPES.CLEAR_DELETE_ORDER_PAY
})
// END Delete OrderPay

// Get affectations movements by ID actions
export const getAffectationsMovementsByIDOrderPayRequest = () => ({
	type: ACTION_TYPES.GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST
});
export const getAffectationsMovementsByIDOrderPayRequestSuccess = data => ({
	type: ACTION_TYPES.GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const getAffectationsMovementsByIDOrderPayRequestFailure = error => ({
	type: ACTION_TYPES.GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearAffectationsMovementsByIDOrderPayData = () => ({
	type: ACTION_TYPES.CLEAR_GET_AFFECTATIONS_MOVEMENTS_BY_ID_ORDER_PAY
});
// END affectations movements by ID actions


// List order pay types actions
export const listPayOrderTypesRequest = () => ({
	type: ACTION_TYPES.LIST_PAY_ORDER_TYPES_REQUEST
});
export const listPayOrderTypesRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_PAY_ORDER_TYPES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listPayOrderTypesRequestFailure = error => ({
	type: ACTION_TYPES.LIST_PAY_ORDER_TYPES_REQUEST_FAILURE,
	payload: {
		error
	}
});

// List order pay last actions
export const listPayOrderLastRequest = () => ({
	type: ACTION_TYPES.LIST_PAY_ORDER_LAST_REQUEST
});
export const listPayOrderLastRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_PAY_ORDER_LAST_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listPayOrderLastRequestFailure = error => ({
	type: ACTION_TYPES.LIST_PAY_ORDER_LAST_REQUEST_FAILURE,
	payload: {
		error
	}
});
export const clearListOrderPayLast = () => ({
	type: ACTION_TYPES.CLEAR_LIST_ORDER_PAY_LAST
});

// List provision order actions
export const listPovisionOrderRequest = () => ({
	type: ACTION_TYPES.LIST_PROVISION_ORDER_REQUEST
});

export const listPovisionOrderRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_PROVISION_ORDER_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const listPovisionOrderRequestFailure = error => ({
	type: ACTION_TYPES.LIST_PROVISION_ORDER_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearListPovisionOrderData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_PROVISION_ORDER_DATA
});

// END List provision order actions

// List affectations by order pay type
export const listAffectationsByOrderTypeRequest = () => ({
	type: ACTION_TYPES.LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST
});

export const listAffectationsByOrderTypeRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST_SUCCESS,
	payload: {
		data
	}
});

export const listAffectationsByOrderTypeRequestFailure = error => ({
	type: ACTION_TYPES.LIST_AFFECTATION_BY_ORDER_TYPE_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const clearListAffectationsByOrderTypeData = () => ({
	type: ACTION_TYPES.CLEAR_LIST_AFFECTATION_BY_ORDER_TYPE_DATA
});

// END List affectations by order pay type

export const listFigurativesByOPIdRequest = () => ({
	type: ACTION_TYPES.LIST_FIGURATIVE_BY_ORDER_ID_REQUEST
});
export const listFigurativesByOPIdRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_FIGURATIVE_BY_ORDER_ID_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listFigurativesByOPIdRequestFailure = error => ({
	type: ACTION_TYPES.LIST_FIGURATIVE_BY_ORDER_ID_REQUEST_FAILURE,
	payload: {
		error
	}
});

export const listWorkCertificateTypesRequest = () => ({
	type: ACTION_TYPES.LIST_WORK_CERTIFICATE_TYPES_REQUEST
});
export const listWorkCertificateTypesRequestSuccess = data => ({
	type: ACTION_TYPES.LIST_WORK_CERTIFICATE_TYPES_REQUEST_SUCCESS,
	payload: {
		data
	}
});
export const listWorkCertificateTypesRequestFailure = error => ({
	type: ACTION_TYPES.LIST_WORK_CERTIFICATE_TYPES_REQUEST_FAILURE,
	payload: {
		error
	}
});