import config from 'Config';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, buildURLQuery, httpPut, httpDelete } from './httpServices';

const PF_API_BASE_URI = `${config.apis.fundsRequestsApi.URL}`;
const WAGES_TEMPLATES = `${PF_API_BASE_URI}/wage-templates`;
const WAGES_TEMPLATES_RECRUITMENT_METHODS = `${PF_API_BASE_URI}/wage-templates/types`;

// GET wages templates
export const getWagesTemplates = async (accessToken, params) => {
	let queryString = "";
	let URL = "";
	const options = buildDefaultOptions(accessToken);
	let response;

	if(params != undefined) {
		const setBuildURLQuery = {
			page: params?.page || 0,
			size: params?.size || 10,
			sort: params?.sort || 'id,asc',
			administrativeServiceId: params?.administrativeServiceId,
			name: params?.name,
			type: params?.type
		};		
		queryString = buildURLQuery(setBuildURLQuery);
		URL = `${WAGES_TEMPLATES}?${queryString}`;
	} else {
		URL = `${WAGES_TEMPLATES}`;
	}

	try {
		response = await httpGet(URL, options);
	} catch (error) {
		return error;
	}

	return response;
};

// POST wages templates
export const newWagesTemplates = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	let response;

	try {
		response = await httpPost(WAGES_TEMPLATES, params, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};

// PUT wages templates
export const editWagesTemplates = async (accessToken, params) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${WAGES_TEMPLATES}/${params?.id}`;
	let response;

	try {
		response = await httpPut(URL, params, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};

// DELETE wages templates
export const deleteWagesTemplates = async (accessToken, id) => {
	const options = buildDefaultOptions(accessToken);
	const URL = `${WAGES_TEMPLATES}/${id}`;
	let response;

	try {
		response = await httpDelete(URL, options);
	} catch (error) {
		return error?.response || error;
	}
	return response;
};

export const getRecruitmentMethods = async (accessToken) => {
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet(WAGES_TEMPLATES_RECRUITMENT_METHODS, options);
	}
	catch (error) {
		return error;
	}

	return response;
}
