import React, { useEffect, useState } from 'react';
import config from 'Config';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearch, faPlus, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { getUserPermissionsExecutionBudget } from 'src/redux/login/loginReducer';
import PageTitle from 'src/components/general/PageTitle';
import StepIndicator from 'src/components/stepIndicator/StepIndicator';
import { getAccessToken } from 'src/redux/login/loginReducer';
import { getReportByURL } from 'src/services/reportsServices';
import { getNewAffectationSelectedCredits, getNewAffectationResponseData, getNewAffectationAdministrativeDocumentData, getAffectationHistoryTransactionsByIdData } from 'src/redux/affectation/affectationReducer';
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer';
import { getReportAffectationsByAdministrativeDocumentDownloadIsFetching, getReportAffectationsByAdministrativeDocumentIsFetching } from 'src/redux/reports/reportsReducer';
import { isNotEmptyArray } from 'src/services/validationService';
import { formatterPeso, downloadStreamFile } from 'src/utils/utils';
import { CREDIT_EXECUTION } from 'src/utils/constants';
import { tryGetAvailableAffectationHistoryTransactionsById } from 'src/redux/affectation/affectationActionCreator';
import { seatNumber, dateOfOperation, typesOfAffectation, dateImpact, subpartialDeparture, nroCredit, amountNumber, attachedFiles } from 'src/utils/label.js';
import { tryGetLatestAffectationTransaction } from 'src/redux/administrativeDocument/administrativedocumentActionCreator';
import { newInvolvement, impactsBudgetItems } from 'src/utils/label';
import { clearNewAffectationDataSelectedCredits } from 'src/redux/affectation/affectationActions';
import { CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE } from 'src/utils/constants';
import ActionIcon from 'src/components/general/ActionIcon';
import { tryGetAffectationSubcode } from 'src/redux/affectation/affectationActionCreator';
import DetailModal from 'src/components/pages/CreditExecution/DetailModal';

import {ROUTE_PATHS as ROUTES}  from 'src/routes';
import { tryGetReportAffectationsByAdministrativeDocumentDirectDownload } from 'src/redux/reports/reportsActionCreactor';
import AppLoading from 'src/components/common/AppLoading';

const CreditExecutionNewAffectationStepFourPage = props => {

	const dispatch = useDispatch();
	const accessToken = useSelector( state => getAccessToken(state) );
	const executionBudgetPermissions = useSelector(state => getUserPermissionsExecutionBudget(state));

	//Period
	const globalSelectedPeriod = useSelector( state => getGlobalDataSelectedPeriod(state) );

	// Credits
	const credits = useSelector( state => getNewAffectationSelectedCredits(state) )
	const administrativeDocument = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

	const reportAffectationByAdministrativeDocumentIsFetching = useSelector(state => getReportAffectationsByAdministrativeDocumentIsFetching(state));

	// Search administrative document
	const transactions = useSelector(state => getAffectationHistoryTransactionsByIdData(state)?.data);
	let hasAffectations;
	let affectations;

	const [showMsgReportInProcess, setShowMsgReportInProcess] = useState(false);

	useEffect(()=> {
		const params = {
			year: administrativeDocument?.year,
			number: administrativeDocument?.number,
			organismCode: administrativeDocument?.organizationCode
			};
			dispatch(tryGetLatestAffectationTransaction(params)).then( response => {
				params.id = response?.id;
				dispatch(tryGetAvailableAffectationHistoryTransactionsById(params));
			});	
	}, []);
	
	if (transactions == undefined) {
		hasAffectations = false;
	} else {
		hasAffectations = true;
		affectations = transactions[0].affectations
	}	

	// POST Response data
	const affectationTransactionId = useSelector( state => getNewAffectationResponseData(state) )?.id;
	const hasAffectationTransactionId = affectationTransactionId != undefined ? true : false;

	const onClickGoToCreditExecution = () => { dispatch( push(CREDIT_EXECUTION) ) };

	const administrativeDocumentData = useSelector(state => getNewAffectationAdministrativeDocumentData(state));

    // Download report by administrative document
    const reportAffectationsByAdministrativeDocumentDownloadIsFetching = useSelector(state => getReportAffectationsByAdministrativeDocumentDownloadIsFetching(state));

	// Get transaction report
	const onClickDownloadReport = () => {

		if (hasAffectationTransactionId) {

			setShowMsgReportInProcess(false);

			const paramsToSend = {
				periodId: globalSelectedPeriod?.id,
				affectationTransactionId: affectationTransactionId,
				outputFormat: 1
			};
			dispatch(tryGetReportAffectationsByAdministrativeDocumentDirectDownload(paramsToSend));
		}
	};

	const onClickNewAffectation = () => {
		dispatch(clearNewAffectationDataSelectedCredits());
		dispatch(push(CREDIT_EXECUTION_NEW_AFFECTATION_STEP_ONE));
	}
	// Modal Credit details
	const [show, setShow] = useState(false);
	const [dataModal, setDataModal] = useState();
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	//on click modal credit
	const onClickModalCredit = (creditData) => {
		const labelNumber = creditData?.split(' - ')[0];
		const matchCreditLabel = (credits, labelNumber) => {
			return credits?.find((item)=>Object.values(item).includes(labelNumber))
		}
		const credit = matchCreditLabel(credits, labelNumber);

		const params = {
			filter: {
				sub_code_id: credit?.id,
				administrative_document_organism_code: administrativeDocumentData?.organizationCode,
				administrative_document_number: administrativeDocumentData?.number,
				administrative_document_year: administrativeDocumentData?.year
			}
		};

		dispatch(tryGetAffectationSubcode(params)).then(
			response => {
				if (response?.status == 200 && isNotEmptyArray(response?.data?.data)) {
					credit.balances = response?.data?.data[0]?.balance;
				}
				setDataModal(credit);
				handleShow(true);
			}
		);
	};

	
	return <Container fluid >
		<Card className='mb-5'>

			<PageTitle text={impactsBudgetItems}/>

			<Container fluid >

				<StepIndicator steps={config.appSettings.steps.creditExecution} current={3} className='mt-2 mb-3'/>

				<Card className='mb-3'>
					<Card.Header className='h6'>
						Nueva afectación
					</Card.Header>
					<Card.Body>
						
						<h5 className='text-main-color text-center mt-4 mb-5'>
							¡Nueva/s afectación/es generada/s correctamente!
						</h5>

						<div className='text-center'>
							<Button variant='success' className='mb-4' onClick={onClickDownloadReport} disabled={!hasAffectationTransactionId || reportAffectationByAdministrativeDocumentIsFetching}>
								<FontAwesomeIcon i icon={faFilePdf} className='mr-2'/>
								Generar PDF
							</Button>
						</div>

						{
							showMsgReportInProcess &&
								<Alert key={'1'} variant={'primary'} className='mt-4'>
									<b className='text-black-color' >Aviso:</b> El reporte se esta generando en segundo plano y estará disponible en el módulo de 
									<a  className='font-italic text-info cursor-pointer' 
										onClick={() => dispatch(push(ROUTES.REPORT_BUDGET_STATUS_CREDIT_EXECUTION_GENERATED))}>
										&nbsp;reportes generados.
									</a>
								</Alert>
						}
						<hr/>

						{
							hasAffectations
							?
								<>
									<h6 className='text-black-color mt-4 mb-3'>
										Partidas afectadas:
									</h6>
											
									<Table striped bordered hover size='sm'>
										<thead>
											<tr>
												<th className='text-center align-middle'>{seatNumber}</th>
												<th className='text-center align-middle'>{dateOfOperation}</th>
												<th className='text-center align-middle'>{typesOfAffectation}</th>
												<th className='text-center align-middle'>{dateImpact}</th>
												<th className='text-center align-middle'>{subpartialDeparture}</th>
												<th className='text-center align-middle'>{nroCredit}</th>
												<th className='text-center align-middle'>{amountNumber}</th>
												<th className='text-center align-middle'>{attachedFiles}</th>
												<th></th>
											</tr>
										</thead>
										<tbody className='text-black-color'>
											{
												<tr className='font-weight-bold'>
												<td colSpan='6' className='text-left align-middle'>
													Transacción {moment(transactions?.transaction?.created_at).format('DD/MM/YYYY HH:mm')}
												</td>
												<td></td>
												<td>
												</td>
												<td>
												</td>
											</tr>
											}
											{
												hasAffectations
												?
												affectations.map( item => (
													<tr key={item?.id}>
														<td className='text-center align-middle'>
															{item?.accountingNumber}
														</td>
														<td className='text-center align-middle'>
															{moment(item?.createdAt).format('DD/MM/YYYY')}
														</td>
														<td className='text-center align-middle'>
															{item?.affectationStatusLabel}
														</td>
														<td className='text-center align-middle'>
															{moment(item?.affectationDate).format('DD/MM/YYYY')}
														</td>
														<td className='text-center align-middle'>
															{item?.subPartialBudget?.number}
														</td>
														<td className='text-center align-middle'>
															{item?.budgetLabel}
														</td>
														<td className='text-center align-middle'>
															{formatterPeso.format(item?.amount)}
														</td>
														<td className='text-center align-middle'>
															
														</td>
														<td className='text-center align-middle'>
															<ActionIcon size='lg' id='details-credit' toolTipText='Detalle de la partida' icon={faSearch} onClick={() => onClickModalCredit(item.budgetLabel)} />
														</td>
													</tr>
												))
												:
												null
											}
										</tbody>
									</Table>
								</>
							:
							null
						}
																		
						<div className='text-center'>
							{
								executionBudgetPermissions?.canCreate
									&&
									<Button size='sm' className='mb-4 mt-3' variant='success' onClick={onClickNewAffectation}>
										<FontAwesomeIcon icon={faPlus} className='mr-2' />
										{newInvolvement}
									</Button>
							}
						</div>

						<div className='mt-3 mb-3 d-flex justify-content-around'>
							<Button variant='danger' onClick={onClickGoToCreditExecution}>
								<FontAwesomeIcon icon={faArrowLeft} className='mr-2'/>
								Volver a ejecución de presupuesto
							</Button>
						</div>
					</Card.Body>
				</Card>
			</Container>
		</Card>
		<DetailModal
			show = {show}
			handleClose = {handleClose}
			dataModal = { dataModal }
		/>
		<AppLoading isLoading={reportAffectationsByAdministrativeDocumentDownloadIsFetching} />
	</Container>;
};

export default  CreditExecutionNewAffectationStepFourPage;