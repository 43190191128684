import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { tryListExercises } from 'src/redux/exercise/exerciseActionCreator';
import MenuSecurity from './MenuSecurity';
import MenuBudgetExecution from './MenuBudgetExecution';
import MenuManuals from './MenuManuals';
import MenuFinancialExecution from './MenuFinancialExecution';
import MenuSupplyDepartment from './MenuSupplyDepartment';
import MenuAudit from './MenuAudit';
import MenuNotification from './MenuNotification';
import MenuConfig from './MenuConfig';
import MenuReport from './MenuReport';

const Menu = props => {
	const { user } = props;
	const dispatch = useDispatch();
	
	useEffect(() => {
		dispatch(tryListExercises());
	}, []);

	return (
		<Nav className="me-auto d-flex flex-wrap">
			<MenuSecurity/>
			<MenuBudgetExecution/>
			<MenuFinancialExecution/>
			<MenuSupplyDepartment/>
			<MenuReport/>
			<MenuAudit/>
			<MenuConfig/>
			<MenuNotification/>
			<MenuManuals/>

			{/* // TODO: Ver uso de la pagina CONTROL DE PARTIDA en "/budget-item-control"
				budgetItemControlPermissions?.canView &&
				<Nav.Item onClick={() => [dispatch(getStatusApi(profileName)), dispatch(tryGetActiveAlert(paramsToAlerts))]} >
					<Nav.Link className='nav-level-1' onClick={() => dispatch(push(ROUTES.BUDGET_ITEM_CONTROL))}>
						<FontAwesomeIcon icon={faEye} className='mr-1'/>
						Control partida
					</Nav.Link>
				</Nav.Item>
			*/}

			{/*  // TODO: Ver uso de las paginas en "/budget-item-control", "/budget-subcode"
				false &&
				<Nav.Item>
					<Nav.Link className='nav-level-1' onClick={() => dispatch(push(ROUTES.BUDGET_ITEM_CONTROL))}>
						<FontAwesomeIcon icon={faMoneyBill} className='mr-1' />
						Recursos propios
					</Nav.Link>
				</Nav.Item>
			*/}
		</Nav>
	);
};

export default Menu;