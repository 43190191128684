import config from 'Config';
import { buildDefaultOptions } from './globalServices';
import { httpGet, httpPost, buildURLQuery } from './httpServices';

const API_BASE_URI = `${config.apis.presupuestoApi.URL}`;
const API_BASE_URI_V2 = `${config.apis.presupuestoApi.URL_V2}`;
const SUBCODES_ENDPOINT_URI = `${API_BASE_URI}/expenses/sub-codes`;
const SUBCODES_ENDPOINT_URI_V2 = `${API_BASE_URI_V2}/expenses/sub-codes`;
const SUBCODE_DETAILS_ENDPOINT_URI = '/details';
const SUBCODE_AFFECTATIONS_ENDPOINT_URI = '/affectation-transactions';
const SUBCODE_FILE_ENDPOINT_URI = `${API_BASE_URI}/expenses/reports/budget-execution-search-result`;
const SUBCODE_AFFECTATIONS_HISTORY_ENDPOINT_URI = '/affectation-history';

export const listSubcode = async ( accessToken, params ) => {
	const options = buildDefaultOptions(accessToken);
	const jsonFilter = params?.filter ? JSON.stringify(params?.filter) : undefined;

	const setbuildURLQuery = {
		pageSize: params?.pageSize,
        page: params?.page,
        filter: jsonFilter
	}
	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${SUBCODES_ENDPOINT_URI}?${queryString}`;

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
}

export const getSubcodeDetails = async ( accessToken, params) => {

	const subcodeId = params?.subcodeId;
	const period_rp_id = params?.period_rp_id;

	const filter = {
		period_rp_id
	};
	const jsonFilter = filter?.period_rp_id ? JSON.stringify(filter) : undefined;
	const setbuildURLQuery = {
		filter: jsonFilter
	};

	const queryString = buildURLQuery(setbuildURLQuery);

	const URL = `${SUBCODES_ENDPOINT_URI}/${subcodeId}${SUBCODE_DETAILS_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

export const getSubcodeData = async ( accessToken, params) => {

	const options = buildDefaultOptions(accessToken);
	const jsonFilter = params?.filter ? JSON.stringify(params?.filter) : undefined;

	const setbuildURLQuery = {
		pageSize: 10,
        page: 1,
        filter: jsonFilter
	}

	const queryString = buildURLQuery(setbuildURLQuery);
	
	const URL = `${SUBCODES_ENDPOINT_URI}?${queryString}`;

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}

	return response;
};

export const getFileSubcode = async ( accessToken, subcodeData) => {
	const options = buildDefaultOptions(accessToken, 'blob');
	const jsonFilter = subcodeData?.filter ? JSON.stringify(subcodeData?.filter) : undefined;
	
	const setbuildURLQuery = {
		pageSize: subcodeData?.pageSize,
		page: subcodeData?.page,
		filter: jsonFilter
	}
	const queryString = buildURLQuery(setbuildURLQuery);
	const URL = `${SUBCODE_FILE_ENDPOINT_URI}?${queryString}`;
	
	let response;
	
	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
}

export const postSubcode = async ( accessToken, data ) => {
	const URL = `${SUBCODES_ENDPOINT_URI}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpPost( URL, data, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

// Subcode Affectations
export const getSubcodeAffectations = async ( accessToken, subcodeId) => {
	const URL = `${SUBCODES_ENDPOINT_URI}/${subcodeId}${SUBCODE_AFFECTATIONS_ENDPOINT_URI}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

// Subcode Affectations History
export const getSubcodeAffectationsHistory = async ( accessToken, params) => {

	const subcodeId = params?.subcodeId;
	const page = params?.page || 1;
	const pageSize = params?.pageSize || 10;

	let jsonFilter = {};

	let filterToSend = {
		...params?.filter
	};

	jsonFilter = filterToSend ? JSON.stringify(filterToSend) : undefined;
	
	const setbuildURLQuery = {
		filter: jsonFilter,
		page,
		pageSize
	};

	const queryString = buildURLQuery(setbuildURLQuery);

	const URL = `${SUBCODES_ENDPOINT_URI_V2}/${subcodeId}${SUBCODE_AFFECTATIONS_HISTORY_ENDPOINT_URI}?${queryString}`;
	const options = buildDefaultOptions(accessToken);

	let response;

	try {
		response = await httpGet( URL, options );
	}
	catch( error ) {
		return error;
	}
	
	return response;
};

// Create compensation
export const postCompensation = async (accessToken, data) => {

	const preloadFilter = {
		isPreload : data?.preloadFilter
	}

	const jsonFilter = preloadFilter?.isPreload ? JSON.stringify(preloadFilter) : undefined;

	const setbuildURLQuery = {
		filter: jsonFilter
	}
	const queryString = buildURLQuery(setbuildURLQuery);

	const options = buildDefaultOptions(accessToken);
	const URL = `${SUBCODES_ENDPOINT_URI_V2}?${queryString}`;

	let response;

	try {	
		response = await httpPost(URL, data, options);
	}
	catch (error) {
		return error;
	}

	return response;

};