import React, { useState, useEffect } from 'react';
import { Container, Image } from 'react-bootstrap';

import loading from 'App/assets/images/loading.gif';

const AppLoading = props => {

	return <>
	{props?.isLoading &&
		<div id="appLoading">
			<div className="loading-container text-black-color">
			<Image src={loading} />
			<span>Cargando...</span>
			</div>
		</div>
	}			
	</>;
};

export default AppLoading;