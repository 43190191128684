import React, { useEffect, useState } from 'react';
import config from 'Config';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Card, Form, Row, Col, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import FormFieldError from 'src/components/general/FormFieldError';
import { showSuccess } from 'src/redux/globalData/globalDataActionCreator';
import { tryCreateLegalInstrument, tryListLegalInstrument } from 'src/redux/legalInstrument/legalInstrumentActionCreator';
import { getSelectedLegalInstrument } from 'src/redux/credit/creditReducer';
import { getLegalInstrumentCreateIsFetching, getLegalInstrumentListIsFetching, getLegalInstrumentListData } from 'src/redux/legalInstrument/legalInstrumentReducer';
import { clearSelectedLegalInstrument, setSelectedLegalInstrument } from 'src/redux/credit/creditActions';
import { clearListLegalInstrument } from 'src/redux/legalInstrument/legalInstrumentActions';
import { isNotEmptyArray } from 'src/services/validationService';
import { parseIntOrUndefined } from 'src/utils/utils';
import { tipe, file, description, numberlegalInstrument, numberAbbreviation, dateLegalInstrument, date, legalInstrumetnFound, numberLegalInstrumentRequiredMsg, fileMaxSizeMsg, dateRequiredMsg } from 'src/utils/label';

const PreloadLegalInstrumentForm = ({ handlerSubmitPreload }) => {

	const dispatch = useDispatch();
	const { register, handleSubmit, errors, getValues, reset } = useForm();

    const LEGAL_INSTRUMENT_TYPES = config.appSettings.LEGAL_INSTRUMENTS_TYPES;
    const todayDate = moment().format('YYYY-MM-DD');
	const [legalInstrumentCode, setLegalInstrumentCode] = useState(false);
	const [descriptionCode, setDescriptionCode] = useState(false);

    const [onClickCreateLegalInstrFlag, setOnClickCreateLegalInstrFlag] = useState(false);

	const selectedLegalInstrument = useSelector(state => getSelectedLegalInstrument(state));
    const hasFoundlegalInstrument = useSelector(state => getLegalInstrumentListData(state))?.records?.length < 1;
    const legalInstrumentSearchIsFetching = useSelector(state => getLegalInstrumentListIsFetching(state));
    const legalInstrumentCreateIsFetching = useSelector(state => getLegalInstrumentCreateIsFetching(state));
    const disabledLegalInstument = selectedLegalInstrument || legalInstrumentSearchIsFetching; 
    const allowInstrumentCreationButton = hasFoundlegalInstrument && !selectedLegalInstrument;

	// Validations
	const numberValidationObj = { required: numberLegalInstrumentRequiredMsg };
	const dateValidationObj = { required: dateRequiredMsg };
	const fileValidationObj = {
		validate: file => {
			const sizeInMB = file[0]?.size / 1024 / 1024;
			return (sizeInMB >= 5 || sizeInMB == undefined) ? fileMaxSizeMsg : undefined;
		}
	};
	const [dateControlMsg, setDateControlMsg] = useState(false);

    const onClickRemoveSelectedLegalInstrument = () => {
		reset();
		dispatch(clearListLegalInstrument());
        dispatch(clearSelectedLegalInstrument());
	};

	const onChangeLegalIntrumentCode = value => {
        const code = parseIntOrUndefined(value);
        code < 1 || code > 99999 ? setLegalInstrumentCode(true) : setLegalInstrumentCode(false);
    };

	const onChangeDescriptionCode = value => {
        value.length >= 255 ? setDescriptionCode(true) : setDescriptionCode(false);
    };

	const searchLegalInstrument = () => {
        cleanUp();
		const dateControl = '1970-01-01';
		const formaterDate = getValues('date');

		if (formaterDate && formaterDate < dateControl){
			setDateControlMsg('Fecha incorrecta.')
		} else if (formaterDate && formaterDate > dateControl){
			setDateControlMsg(false)
		}

		const yearSelected = new Date(getValues('date'))?.getFullYear();

		const searchParam = {
			number: getValues('number'),
			date: formaterDate,
			idLegalInstrumentType: getValues('legalInstrumentTypeId')
		};

		if (searchParam.number && !isNaN(yearSelected) && searchParam.idLegalInstrumentType && !dateControlMsg) {
			dispatch(tryListLegalInstrument(searchParam)).then(data => {
				const records = data?.records;
				if (isNotEmptyArray(records)) {
					reset();
					showSuccess(dispatch, legalInstrumetnFound);
					dispatch(setSelectedLegalInstrument(records[0]));
				}
			})
		}
	};

    const cleanUp = () => {
        if(!onClickCreateLegalInstrFlag){
            dispatch(clearListLegalInstrument());
            dispatch(clearSelectedLegalInstrument());
        }
    };

    const getTypeObjById = typeId => LEGAL_INSTRUMENT_TYPES?.find(item => item.id == typeId);
    const onFormSubmit = data => {
        cleanUp();
		data.legalInstrumentTypeName = getTypeObjById(data?.legalInstrumentTypeId)?.name;
		dispatch(tryCreateLegalInstrument(data))
			.then( response => {
				if(response?.status == 200) {
					dispatch(setSelectedLegalInstrument(response?.data));
				}
			});
	};

    useEffect(() => {
        cleanUp();

    }, [])

	return <>
        <Form className='my-4 text-black-color' onSubmit={handleSubmit(onFormSubmit)}>
            <Card variant='success' className='my-3'>
                <Card.Header>
                    <h5 className='mb-0 text-white-color'>
                        Datos del Instrumento Legal Asociado
                    </h5>
                </Card.Header>
            </Card>
            {
                selectedLegalInstrument &&
                <>
                    <Container fluid className='mt-3 px-5'>
                        <Card className='text-black-color shadow bg-white rounded mt-3 mb-3 p-3'>
                            <Row className="pb-3">
                                <Col>
                                    <FontAwesomeIcon icon={faTimes} className='text-black-color cursor-pointer close-button' onClick={onClickRemoveSelectedLegalInstrument} />
                                </Col>
                            </Row>
                            <div className='d-flex justify-content-center'>
                                <div className='d-flex flex-column mr-4 text-right font-weight-bold'>
                                    <span>{tipe}:</span>
                                    <span>{numberAbbreviation}:</span>
                                    <span>{date}:</span>
                                </div>
                                <div className='d-flex flex-column mr-4 text-left'>
                                    <span>{selectedLegalInstrument?.legalInstrumentType?.name}</span>
                                    <span>{selectedLegalInstrument?.number}</span>
                                    <span>{moment(selectedLegalInstrument?.date).format('DD/MM/YYYY')}</span>
                                </div>
                                <div className='d-flex flex-column mr-4 text-right font-weight-bold'>
                                    <span>Descripcion:</span>
                                </div>
                                <div className='overflow-auto span-container-mw'>
                                    <span>{selectedLegalInstrument?.description}</span>
                                </div>
                           </div>
                        </Card>
                    </Container>
                    <hr />
                </>
            }
            <Row className='justify-content-end font-weight-bold font-italic mr-4'>
                <span className='text-danger d-flex mandatory-label'>
                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                    Obligatorio
                </span>
            </Row>
            <Col className='legal-instrument-center-modal'>
                <Form.Group as={Row}>
                    <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm='4'>
                        <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                        {tipe}:
                    </Form.Label>
                    <Col sm='4'>
                        <Form.Control
                            as='select'
                            name='legalInstrumentTypeId'
                            disabled={disabledLegalInstument}
                            ref={register}
                            onChange={searchLegalInstrument}
                        >
                        {
                            LEGAL_INSTRUMENT_TYPES?.map(item => (<option className='text-black-color' value={item.id} key={item?.id}>
                                {item.name}
                            </option>))
                        }
                        </Form.Control>
                    </Col>
                </Form.Group>

            <Form.Group as={Row} controlId='formHorizontalEmail'>
                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm='4'>
                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                    {numberlegalInstrument}:
                </Form.Label>
                <Col sm='4'>
                    <Form.Control
                        type='number'
                        name='number'
                        max={99999}
                        min={1}
                        placeholder={numberlegalInstrument}
                        disabled={disabledLegalInstument}
                        ref={register(numberValidationObj)}
                        onBlur={searchLegalInstrument}
                        onChange={event => onChangeLegalIntrumentCode(event.target.value)}
                    />
                    <FormFieldError errors={errors?.number} />
                    {
                        legalInstrumentCode &&
                        <div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
                            Valor no válido.
                        </div>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm='4'>
                    <FontAwesomeIcon icon={faAsterisk} size='xs' className='mr-1 text-danger mandatory-asterisk' />
                    {dateLegalInstrument}:
                </Form.Label>
                <Col sm='4'>
                    <Form.Control
                        type='date'
                        name='date'
                        disabled={disabledLegalInstument}
                        ref={register(dateValidationObj)}
                        max={todayDate}
                        className='text-center'
                        onBlur={searchLegalInstrument}
                    />
                    <FormFieldError errors={errors?.date} />
                    {
                        dateControlMsg &&
                        <div className='alert alert-danger form-field-error mb-0 py-1 mt-1' role='alert'>
                            {dateControlMsg}
                        </div>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm='4'>
                    {description}:
                </Form.Label>
                <Col sm='4'>
                    <Form.Control
                        as='textarea'
                        name='description'
                        maxlength='255'
                        disabled={disabledLegalInstument}
                        ref={register}
                        onChange={event => onChangeDescriptionCode(event.target.value)}
                    />
                    {
                        descriptionCode &&
                        <div className='alert alert-warning form-field-error mb-0 py-1 mt-2' role='alert'>
                            Límite de caracteres alcanzado.
                        </div>
                    }
                </Col>
            </Form.Group>

            <Form.Group as={Row}>
                <Form.Label className='text-right text-black-color pr-0 d-flex mandatory-label' column sm='4'>
                    {file} (.pdf, .png, .jpg):
                </Form.Label>
                <Col sm='4'>
                <Form.Control
                        type='file'
                        name='file'
                        accept='.pdf,.jpg,.png'
                        disabled={disabledLegalInstument}
                        ref={register(fileValidationObj)}
                        className='text-black-color'
                    />
                    <FormFieldError errors={errors?.file} />
                </Col>
            </Form.Group>

            </Col>
            <div className='d-flex justify-content-end'>
            {
                allowInstrumentCreationButton &&
                    <Button
                        className='text-white-color mr-4'
                        type='submit'
                        variant='success'
                        size='lg'
                        disabled={legalInstrumentSearchIsFetching || legalInstrumentCreateIsFetching}
                        onClick={() => setOnClickCreateLegalInstrFlag(true)}
                    >
                        Crear Instrumento
                    </Button>
            }
                <Button
                    className='text-white-color'
                    type='button'
                    variant='success'
                    size='lg'
                    disabled={legalInstrumentSearchIsFetching || !selectedLegalInstrument || legalInstrumentCreateIsFetching}
                    onClick={() =>handlerSubmitPreload()}
                >
                    Guardar Modificación de Crédito
                    <FontAwesomeIcon icon={faSave} className='ml-2' />
                </Button>
            </div>
        </Form>
    </>
};

export default PreloadLegalInstrumentForm;