import React, { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useForm } from "react-hook-form";
import { Card, Row, Image, Form, Button, Spinner } from 'react-bootstrap';
import config from 'Config';

import logo from 'App/assets/images/logo_gobierno_misiones.png';
import LoggedOutCard from 'src/components/general/LoggedOutCard';
import FormFieldError from 'src/components/general/FormFieldError';
import { createNewPassword, send, restartLog, newPassword, passwordConfirmSameMsg, confirNewPassword } from 'src/utils/label';

import { validatePasswordFormat, PASSWORD_INVALID_MSG } from 'src/services/validationService';
import { clearUserList } from 'src/redux/user/userActions';

import { getUserListData, getUserListIsFetching, getUserEditIsFetching } from 'src/redux/user/userReducer';
import { tryPutPasswordFirstLoginUser, tryGetUserList } from 'src/redux/user/userActionCreator';
import { getIsFetching, getFirstLoginUser } from 'src/redux/login/loginReducer';
import { LOGIN_URI } from 'src/utils/constants';

const SetPasswordPage = props => {

	const dispatch = useDispatch();
	const { register, handleSubmit, errors, getValues } = useForm();
	const isFetching = useSelector( state => getIsFetching(state) );
	const listUserIsFetching = useSelector(state => getUserListIsFetching(state));
	const userEditIsFetching = useSelector(state => getUserEditIsFetching(state));

	const passwordConfirmRequiredMsg = 'Debe ingresar una confirmación de contraseña.';

	const samePasswordValidation = value => {
		const passwordInputValue = getValues('password');
		if (passwordInputValue) {
			return (value === passwordInputValue ? undefined : passwordConfirmSameMsg);
		}
		return true;
	};

	const passwordValidation = { validate: value => (validatePasswordFormat(value) ? undefined : PASSWORD_INVALID_MSG) };
	const passwordConfirmValidation = { required: passwordConfirmRequiredMsg, validate: samePasswordValidation };

	const onClickGoToLoginPage = event => {
		event.preventDefault();
		dispatch( push(LOGIN_URI) );
	};
	//User Id
	const listUserData = useSelector(state => getUserListData(state));
	

	const firstLoginUser = useSelector(state => getFirstLoginUser(state));

	const onFormSubmit = data => {
		const userFound = listUserData?.records?.find(item => item.email == firstLoginUser.email);
		const userXApplicationXProfile = {};
		userXApplicationXProfile.profileId = userFound?.profileId;
		const userData = {
			password: data?.password,
			id: userFound?.id,
			name: firstLoginUser?.username,
			alias: firstLoginUser?.alias,
			email: firstLoginUser?.email,
			valid: userFound?.valid,
			userXApplicationXProfile
		};
		dispatch(tryPutPasswordFirstLoginUser(userData)).then( response => {
			if (response?.status == 200) {
				dispatch(clearUserList());
			}
		});
	};

	useEffect(() => {
		dispatch(tryGetUserList(1, firstLoginUser?.email));
	}, []);

	const cardBody = <>
		<Row className='h-100 justify-content-center align-items-center my-3'>
			<Image src={logo} fluid />
		</Row>

		<Card.Title className='text-center mb-4 mt-1 text-main-color'>
			<span className='h4 text-main-color'>
				{config.appSettings.name}
			</span>
		</Card.Title>

		<hr />

		<h3 className='text-black-color text-center mb-3'>
			{createNewPassword}
		</h3>

		<Form onSubmit={handleSubmit(onFormSubmit)}>
			<Form.Group className='mx-3' controlId='passwordControl'>
				<Form.Label size='lg' className='text-main-color'>
					{newPassword}
				</Form.Label>
				<Form.Control ref={register(passwordValidation)} size='lg' type='password' placeholder='•••••••' name='password' id='password' className='placeholderOpacity' />
				<FormFieldError errors={errors.password} />
			</Form.Group>
			<Form.Group className='mx-3' controlId='passwordControl'>
				<Form.Label size='lg' className='text-main-color'>
					{confirNewPassword}
				</Form.Label>
				<Form.Control ref={register(passwordConfirmValidation)} size='lg' type='password' placeholder='•••••••' name='passwordConfirm' id='passwordConfirm' className='placeholderOpacity' />
				<FormFieldError errors={errors.passwordConfirm} />
			</Form.Group>
			<Form.Group className='mx-3'>
				<Button size='lg' variant='success' className='w-100 mt-3 btn-primary' type='submit' disabled={isFetching || listUserIsFetching || userEditIsFetching}>
					{
						isFetching ?
							<Spinner animation='border' />
							:
							send
					}
				</Button>
			</Form.Group>
		</Form>

		<div className='text-center'>
			<a href='#' onClick={onClickGoToLoginPage}>
				{restartLog}
			</a>
		</div>
	</>;

	return <LoggedOutCard cardContent={cardBody}/>;
};

export default SetPasswordPage;